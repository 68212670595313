import React, {useState, useEffect} from 'react';

import {isAuthenticated} from '../../services/auth';
import { Redirect } from 'react-router-dom';

import logo from '../../assets/image/logo_b.png';

import '../../assets/style/page/resetpassword/index.scss';

const PageResetPassword = (props) => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isToken, setIsToken] = useState(false);

    const executeAlterPassword = async (e) => {
        //props.history.push('/ResetPassword');
    }

    const executeSendAlterPassword = async (e) => {
        //props.history.push('/ResetPassword');
    }

    useEffect(()=> {
        const token = props.match.params.token;
        if(token){
            console.log('Token', token);
            setIsToken(true);
        }else{
            setIsToken(false);
            console.log('Sem token');
        }
    }, [isToken, props]);

    if(isAuthenticated() && !props.match.params.token){
        return (
            <Redirect to={{pathname:'/User', state:{from:props.location}}} />
        )
    }

    return (
        <div className="page-resetpassword">
            <div className="box-resetpassword">
                <div className="area-logo">
                    <img src={logo} alt=""/>
                </div>
                <div className="area-form">
                    {
                        isToken ?
                            <form>
                                <div>
                                    <p>
                                        {email}
                                    </p>
                                </div>
                                <div>
                                    <input required type='password' maxLength={100} value={password} onInput={(e)=>{ setPassword(e.currentTarget.value) }} placeholder="Senha" />
                                </div>
                                <div>
                                    <input required type='password' maxLength={100} value={confirmPassword} onInput={(e)=>{ setConfirmPassword(e.currentTarget.value) }} placeholder="Confirmar Senha" />
                                </div>
                                <div>
                                    <button type='submit' onClick={()=> { executeAlterPassword(); }}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        Alterar
                                    </button>
                                </div>
                            </form>
                        :
                        <form>
                                <div>
                                    <input required type='email' maxLength={100} value={email} onInput={(e)=>{ setEmail(e.currentTarget.value) }} placeholder="E-mail" />
                                </div>
                                <div>
                                    <button type='submit' onClick={()=> { executeSendAlterPassword(); }}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        Enviar
                                    </button>
                                </div>
                            </form>
                    }
                </div>
            </div>
        </div>
    )
};

export default PageResetPassword;