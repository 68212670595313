import { getToken } from "../../auth";

//const URL_BASE = 'http://localhost/wa2disksistemas/api/';
//const URL_BASE = 'http://10.0.0.132/wa2disksistemas/api/';
const URL_BASE = 'https://dev.disksistemas.com.br/api/';

export const executeLogin = async (user, password) => {

    const header = new Headers();

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify({user: user, password: password})
    };

    const request = await fetch(`${URL_BASE}User/Login`, params);
    const json = await request.json();

    return json;

};

export const getUserList = async (filter = {}) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}User/ListUsers`, params);
    const json = await request.json();

    return json;

}

export const getUserById = async (id) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}User/UserData/${id}`, params);
    const json = await request.json();

    return json;

}


export const createUser = async (user) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');
    header.append('Content-Length',JSON.stringify(user).length);
    

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(user)
    };

    const request = await fetch(`${URL_BASE}User/Create`, params);
    const json = await request.json();

    return json;

}

export const updateUser = async (user) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');
    header.append('Content-Length',JSON.stringify(user).length);
    

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(user)
    };

    const request = await fetch(`${URL_BASE}User/Update`, params);
    const json = await request.json();

    return json;

}


export const getSecurityUserById = async (id) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}Security/getSecurityUser/${id}`, params);
    const json = await request.json();

    return json;

}

export const createSecurity = async (security) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');
    header.append('Content-Length',JSON.stringify(security).length);
    

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(security)
    };

    const request = await fetch(`${URL_BASE}Security/Create`, params);
    const json = await request.json();

    return json;

}

export const updateSecurity = async (security) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');
    header.append('Content-Length',JSON.stringify(security).length);
    

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(security)
    };

    const request = await fetch(`${URL_BASE}Security/Update`, params);
    const json = await request.json();

    return json;

}

export const getClientList = async (filter = {}) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}Client/ListClients`, params);
    const json = await request.json();

    return json;

}

export const getClientById = async (id) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}Client/ClientData/${id}`, params);
    const json = await request.json();

    return json;

}

export const createClient = async (client) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');
    header.append('Content-Length',JSON.stringify(client).length);
    

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(client)
    };

    const request = await fetch(`${URL_BASE}Client/Create`, params);
    const json = await request.json();

    return json;

}

export const updateClient = async (client) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');
    header.append('Content-Length',JSON.stringify(client).length);
    

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(client)
    };

    const request = await fetch(`${URL_BASE}Client/Update`, params);
    const json = await request.json();

    return json;

}

export const createAddress = async (address) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');
    header.append('Content-Length',JSON.stringify(address).length);
    

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(address)
    };

    const request = await fetch(`${URL_BASE}Address/Create`, params);
    const json = await request.json();

    return json;

}

export const getAddressById = async (id) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}Address/AddressList/${id}`, params);
    const json = await request.json();

    return json;

}

export const updateAddress = async (address) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');
    header.append('Content-Length',JSON.stringify(address).length);
    

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(address)
    };

    const request = await fetch(`${URL_BASE}Address/Update`, params);
    const json = await request.json();

    return json;

}

export const deleteAddress = async (address) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');
    header.append('Content-Length',JSON.stringify(address).length);
    

    const params = {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(address)
    };

    const request = await fetch(`${URL_BASE}Address/Delete`, params);
    const json = await request.json();

    return json;

}

export const createContact = async (contact) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');
    header.append('Content-Length',JSON.stringify(contact).length);
    

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(contact)
    };

    const request = await fetch(`${URL_BASE}Contact/Create`, params);
    const json = await request.json();

    return json;

}

export const getContactById = async (id) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}Contact/ContactList/${id}`, params);
    const json = await request.json();

    return json;

}

export const updateContact = async (contect) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');
    header.append('Content-Length',JSON.stringify(contect).length);
    

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(contect)
    };

    const request = await fetch(`${URL_BASE}Contact/Update`, params);
    const json = await request.json();

    return json;

}

export const deleteContact = async (contact) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');
    header.append('Content-Length',JSON.stringify(contact).length);
    

    const params = {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(contact)
    };

    const request = await fetch(`${URL_BASE}Contact/Delete`, params);
    const json = await request.json();

    return json;

}

export const getOSById = async (id) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}OS/OSList/${id}`, params);
    const json = await request.json();

    return json;

}

export const getBudgetList = async () => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}Budget/BudgetList`, params);
    const json = await request.json();

    return json;
}

export const getBudgetByIdClient = async (id) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}Budget/BudgetList/${id}`, params);
    const json = await request.json();

    return json;
}

export const getBudgetById = async (id) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}Budget/BudgetByid/${id}`, params);
    const json = await request.json();

    return json;
}

export const createBudget = async (_data) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');
    header.append('Content-Length',JSON.stringify(_data).length);
    

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_BASE}Budget/Create`, params);
    const json = await request.json();

    return json;

}

export const createBudgetService = async (_data) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');
    header.append('Content-Length',JSON.stringify(_data).length);
    

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_BASE}Budget/CreateService`, params);
    const json = await request.json();

    return json;

}

export const getContractById = async (id) => {

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}Contract/ContractList/${id}`, params);
    const json = await request.json();

    return json;
    
}


export const servicesGetAll = async () =>{

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}Services/getall`, params);
    const json = await request.json();

    return json;
}

export const finaceMethodPaymentGetAll = async () =>{

    const header = new Headers();
    header.append('Authorization', `${getToken()}`);
    header.append('Access-Control-Allow-Origin', '*');
    header.append('Access-Control-Allow-Headers','*');

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}Finance/methodpaymentgetall`, params);
    const json = await request.json();

    return json;
}
