import React, {useState, useEffect} from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';

import ResumeBudget from '../../../../../components/resumebudget';

import {getOSById, getBudgetByIdClient, getContractById} from '../../../../../services/api/disksistemas';

import UIPrealoder from '../../../../../components/ui/preloader';

const ClientViewService = (props) => {

    const [osList, setOsList] = useState(null);
    const [budgetList, setBudgetList] = useState(null);
    const [contractList, setContractList] = useState(null);
    const [budgetSelected, setBudgetSelected] = useState(null);
    const [showResume, setShowResume] = useState(false);

    useEffect(() => {   
        const getData = async () => {

            const dataBudget = await getBudgetByIdClient(props.match.params.id);
            setBudgetList(dataBudget.data);
            console.log('BUDGET');
            console.log(dataBudget.data);
            
            const dataOS = await getOSById(props.match.params.id);
            setOsList(dataOS.data);
            console.log('OS');
            console.log(dataOS.data);

            const dataContract = await getContractById(props.match.params.id);
            setContractList(dataContract.data);
            console.log('CONTRACT');
            console.log(dataContract.data);
        }

        getData();
    }, [props]);

    const getStatusName = (_status) => {

        let status;

        switch(_status.toLowerCase()){
            case 'concluído':
            case 'finalizado':
            case 'orçamento aprovado':
                status = 'success';
            break
            case 'pendente':
            case 'pendente faturamento':
            case 'aguardando aprovação':
            case 'em preparação':
                status = 'warning';
            break
            case 'cancelado':
                status = 'danger';
            break
            default:
                status = 'success';
        }

        return status;
    }

    const openResume = (id) => {

        setBudgetSelected(id);
        setShowResume(true);

    }

    return(
        <div className="client-view-service">
            <Divider align="left">
                <div className="p-d-inline-flex p-ai-center">
                    <i className="pi pi-dollar p-mr-2"></i>
                    <b>Orçamentos</b>
                </div>
            </Divider>
            <div>
            {!budgetList ? <UIPrealoder /> : ''}
            {

                    budgetList ?
                        <div className="datatable-responsive-demo">
                            <DataTable emptyMessage="Não há dados." value={budgetList} paginator={budgetList.length > 10} rows={10} rowsPerPageOptions={[5, 10, 25]} className="p-datatable-sm p-datatable-responsive-demo p-datatable-striped">
                                <Column style={{width:'5%'}} field="id" header="ID"></Column>
                                <Column field={(budget) => new Date(budget.data_agendamento).toLocaleDateString() + ' - ' + new Date(budget.data_agendamento).toLocaleTimeString() } header="Agendamento"></Column>
                                <Column field={(budget) =>  new Date(budget.data_proposta).toLocaleDateString() + ' - ' + new Date(budget.data_proposta).toLocaleTimeString() } header="Data Proposta"></Column>
                                <Column field={(budget) =>  new Date(budget.validade_proposta).toLocaleDateString() + ' - ' + new Date(budget.validade_proposta).toLocaleTimeString() } header="Validade"></Column>
                                <Column style={{width:'10%', minWidth: '100px'}} field={(budget) => <><Tag style={{width:'100%'}} value={budget.status} severity={getStatusName(budget.status)}></Tag></> } header="Status"></Column>
                                <Column style={{width:'7%', minWidth: '50px'}} className="area-buttons" body={(budget)=> <>
                                    <Button onClick={()=>{ openResume(budget.id) }} className="p-button-rounded p-button-secondary p-mr-2" icon="pi pi-info-circle" />
                                    <Button className="p-button-rounded p-button-info p-mr-2" icon="pi pi-eye" />
                                </>
                                }></Column>
                            </DataTable>
                        </div>
                    :
                    ''
            }    
            </div>
            <Divider align="left">
                <div className="p-d-inline-flex p-ai-center">
                    <i className="pi pi-ticket p-mr-2"></i>
                    <b>Ordem de serviços</b>
                </div>
            </Divider>
            <div>
            {!osList ? <UIPrealoder /> : ''}
            {

                    osList ?
                        <div className="datatable-responsive-demo">
                            <DataTable emptyMessage="Não há dados." value={osList} paginator={osList.length > 10} rows={10} rowsPerPageOptions={[5, 10, 25]} className="p-datatable-sm p-datatable-responsive-demo p-datatable-striped">
                                <Column style={{width:'5%'}} field="id" header="ID"></Column>
                                <Column field={(osList) => new Date(osList.data_agendamento).toLocaleDateString() + ' - ' + new Date(osList.data_agendamento).toLocaleTimeString() } header="Agendamento"></Column>
                                <Column field={(osList) => osList.observacoes } header="Observações"></Column>
                                <Column style={{width:'10%', minWidth: '150px'}} field={(osList) => <><Tag style={{width:'100%'}} value={osList.status} severity={getStatusName(osList.status)}></Tag></> } header="Status"></Column>
                                <Column style={{width:'7%', minWidth: '50px'}} className="area-buttons" body={(osList)=> <>
                                    <Button className="p-button-rounded p-button-secondary p-mr-2" icon="pi pi-info-circle" />
                                    <Button className="p-button-rounded p-button-info p-mr-2" icon="pi pi-eye" />
                                </>
                                }></Column>
                            </DataTable>
                        </div>
                    :
                    ''
            }
            </div>
            <Divider align="left">
                <div className="p-d-inline-flex p-ai-center">
                    <i className="pi pi-book p-mr-2"></i>
                    <b>Contratos</b>
                </div>
            </Divider>
            <div>
            {!contractList ? <UIPrealoder /> : ''}
            {

                contractList ?
                        <div className="datatable-responsive-demo">
                            <DataTable emptyMessage="Não há dados." value={contractList} paginator={contractList.length > 10} rows={10} rowsPerPageOptions={[5, 10, 25]} className="p-datatable-sm p-datatable-responsive-demo p-datatable-striped">
                                <Column style={{width:'5%'}} field="id" header="ID"></Column>
                                <Column field={(contractList) => new Date(contractList.data).toLocaleDateString() + ' - ' + new Date(contractList.data).toLocaleTimeString() } header="Início"></Column>
                                <Column field={(contractList) => new Date(contractList.data_vencimento).toLocaleDateString() + ' - ' + new Date(contractList.data_vencimento).toLocaleTimeString() } header="Vencimento"></Column>
                                <Column style={{width:'10%'}} field={(contractList) => contractList.duracao } header="Duração"></Column>
                                <Column style={{width:'10%', minWidth: '100px'}} field={(contractList) => <><Tag style={{width:'100%'}} value={contractList.status} severity={getStatusName(contractList.status)}></Tag></> } header="Status"></Column>
                                <Column style={{width:'7%', minWidth: '50px'}} className="area-buttons" body={(contractList)=> <>
                                    <Button className="p-button-rounded p-button-secondary p-mr-2" icon="pi pi-info-circle" />
                                    <Button className="p-button-rounded p-button-info p-mr-2" icon="pi pi-eye" />
                                </>
                                }></Column>
                            </DataTable>
                        </div>
                    :
                    ''
            }
            </div>
            <Dialog  maximizable modal header={`Orçamento - ${budgetSelected}`} visible={showResume} style={{ width: '50vw' }}  onHide={() => setShowResume(false)}>
                <ResumeBudget budgetid={budgetSelected} />
            </Dialog>
        </div>
    )

}

export default ClientViewService;