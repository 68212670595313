import React from 'react';

const AddressView =  (props) => {

    return (
        <div className="address-view">
            {props.addressdata.tipo_endereco}
            <div>{ props.addressdata.logradouro} { props.addressdata.numero} { props.addressdata.complemento.length > 0 ? '- ' +  props.addressdata.complemento : ''}, { props.addressdata.bairro} - { props.addressdata.cidade} - { props.addressdata.UF}</div> 
            <div>
                {props.addressdata.ponto_referencia}
            </div>
        </div>
    )

}

export default AddressView;