import React, {useEffect, useState} from 'react';


import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';

import {getUserList} from '../../../services/api/disksistemas';

import {careerList} from '../../../services/data';

import '../../../assets/style/page/user/users/index.scss';
import { NavLink } from 'react-router-dom';

const PageUserUsers = (props) => {

    //console.log('#',props);

    
    const [listUsers, setlistUsers] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    
    const getDataCareer = (_id) => {
        const data = careerList.filter( item => {
            const temp = item.value === 1 ? 0 : item.value;
            return temp === _id;
        });
        
        return data[0];
    }
    
    
    
    useEffect(()=> {
    
        props.onSetpage('Usuários');
        
        const getData = async () => {
            const data = await getUserList();
            //console.log(data);
            setlistUsers(data.data);
        };
        
        getData();
        

    },[props]);

    return(
        <div className='page-user-users'>            
            
            <Toolbar className="bar-add"  right={()=>  <NavLink  to={`/Users/New/`}><Button label="Adicionar usuário" icon="pi pi-plus" iconPos="left" /></NavLink> } />
            <Toolbar className="bar-add"  right={()=> <span className="p-input-icon-left"><i className="pi pi-search" /><InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busca..." /></span> } />
            <div className="datatable-responsive-demo">
                <DataTable globalFilter={globalFilter} value={listUsers} paginator={listUsers.length > 10} rows={10} rowsPerPageOptions={[5, 10, 25]} className="p-datatable-sm p-datatable-responsive-demo p-datatable-striped">
                    <Column style={{width:'5%'}} field="id" header="ID"></Column>
                    <Column field="nome" header="Nome"></Column>
                    <Column field="email" header="E-mail"></Column>
                    <Column body={(user)=><>{getDataCareer(~~user.nivel_acesso).label}</>} header="Cargo"></Column>
                    <Column style={{width:'8%', minWidth: '100px'}} field="ativo" header="Status" body={(client) =>
                        <>
                            <Tag value={client.inativo === '0' ? 'Ativo' : 'Inativo'} severity={client.inativo === '0' ? 'success' : 'danger'}></Tag>
                        </>
                    }></Column>
                    <Column style={{width:'12%', minWidth: '200px'}} className="area-buttons" body={(user)=> <>
                        <NavLink  to={`/Users/Edit/${user.id}`}>
                            <Button className="p-button-rounded p-button-warning p-mr-2" icon="pi pi-pencil" />
                        </NavLink>
                        <NavLink  to={`/Users/View/${user.id}`}>
                            <Button className="p-button-rounded p-button-info p-mr-2" icon="pi pi-eye" />
                        </NavLink>
                    </>
                    }></Column>
                </DataTable>
            </div>
        </div>
    )

}

export default PageUserUsers;