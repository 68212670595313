import React from 'react';
import {BrowserRouter, Switch, Route } from 'react-router-dom';

import PageUserDashboard from '../../../page/user/dashboard';
import PageUserUsers from '../../../page/user/users';
import PageUserUsersView from '../../../page/user/users/view';
import PageUserUsersNew from '../../../page/user/users/new';
import PageUserUsersEdit from '../../../page/user/users/edit';
import PageUserClient from '../../../page/user/client';
import PageUserClientView from '../../../page/user/client/view';
import PageUserClientNew from '../../../page/user/client/new';
import PageUserClientEdit from '../../../page/user/client/edit';
import PageUserBudget from '../../../page/user/budget/';
import PageUserBudgetNew from '../../../page/user/budget/new';
import PageUserBudgetEdit from '../../../page/user/budget/edit';



const UserRouter = (props) => {

    //console.log(props);

    return (
        <BrowserRouter key={props.currentpage}  basename="/User">
            <Switch>
                <Route path="/Calendar" component={() => <h1>Calendar <br /> {new Date().toISOString()} </h1>} />
                <Route path="/Client" render={({match:{url}}) => (
                    <>
                        <Route exact path={`${url}/New`} render={() => (<PageUserClientNew {...props} />)} />
                        <Route exact path={`${url}/View/:id`} render={(p) => (<PageUserClientView onSetpage={props.onSetpage}  {...p} />)} />
                        <Route exact path={`${url}/Edit/:id`} render={(p) => (<PageUserClientEdit onSetpage={props.onSetpage}  {...p} />)} />        
                        <Route exact path={`${url}`} render={() => (<PageUserClient {...props} />)} />
                    </>
                )} />
                <Route path="/Users" render={({match:{url}}) => (
                    <>
                        <Route exact path={`${url}/New`} render={() => (<PageUserUsersNew {...props} />)} />
                        <Route exact path={`${url}/View/:id`} render={(p) => (<PageUserUsersView onSetpage={props.onSetpage}  {...p} />)} />
                        <Route exact path={`${url}/Edit/:id`} render={(p) => (<PageUserUsersEdit onSetpage={props.onSetpage}  {...p} />)} />        
                        <Route exact path={`${url}`} render={() => (<PageUserUsers {...props} />)} />
                    </>
                )} />

                <Route path="/Budget" render={({match:{url}}) => (
                    <>
                        <Route exact path={`${url}/New`} render={(p) => (<PageUserBudgetNew  onSetpage={props.onSetpage} {...p} />)} />
                        <Route exact path={`${url}/Edit/:id`} render={(p) => (<PageUserBudgetEdit onSetpage={props.onSetpage}  {...p} />)} />         
                        {/* <Route exact path={`${url}/View/:id`} render={(p) => (<PageUserUsersView onSetpage={props.onSetpage}  {...p} />)} />*/}
                        <Route exact path={`${url}`} render={() => (<PageUserBudget {...props} />)} />
                    </>
                )} />
                
                <Route path="/:page" component={() => <div>{props.currentpage}</div>} />
                <Route path="" render={(p) => (<PageUserDashboard onSetpage={props.onSetpage}  {...p} />)} />
            </Switch>
        </BrowserRouter>
    )

}

export default UserRouter;