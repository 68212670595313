import React, {useState, useRef} from 'react';

import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import {Password} from 'primereact/password';
import { ToggleButton } from 'primereact/togglebutton';

import {careerList, rulesUser} from '../../../../services/data';

import {createUser, createSecurity} from '../../../../services/api/disksistemas';

import '../../../../assets/style/page/user/users/new/index.scss';

const PageUserUsersNew = (props) => {

    props.onSetpage('Cadastro de Usuário');
    
    
    
    
    const [isAdmin, setIsAdmin] = useState(false);
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [celular, setCelular] = useState('');
    const [inativo, setInativo] = useState('');
    const [nivelAcesso, setNivelAcesso] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');
    
    const rules = useRef();
    const user = useRef();
    
    const userData = {
        nome: null,
        email: null, 
        telefone: null,
        celular: null,
        inativo: false,
        senha: null,
        confirmsenha: null,
        nivel_acesso: null,
        cargo:''
    };

    user.current = user.current ? user.current : userData;  

    let tempRules = {administrador:0, id_usuario: null};

    Object.keys(rulesUser).map(rule => {
        rulesUser[rule].data.map(module => {
            tempRules[module.ref] = 0;
            return module;
        });
        return rule;
    })

    rules.current = rules.current ? rules.current : tempRules;

    const  saveNewUser = async () => {
        //delete user.current.confirmsenha;
        
        let saveuser = user.current;
        delete saveuser.confirmsenha;

        const userid = await createUser(saveuser);
        rules.current.id_usuario = userid.data;

        //console.log('User id', userid);

        const securiry = await createSecurity(rules.current);

        console.log('Security id', securiry);

        props.history.push(`/User/Users/View/${userid.data}`);

    }

    const validadeNewUser = () => {
        let erros = Object.keys(user.current).filter(keys => {
            return user.current[keys] === null;
        });

        if((user.current.senha !== user.current.confirmsenha) && user.current.senha)
            erros.push('senha');
        
        console.log(erros);

        if(erros.length > 0)
            return false;


    }

    return (
        <div className="page-user-users-new">
            <div className="data-user">
                <Divider align="left">
                    <div className="p-d-inline-flex p-ai-center">
                        <i className="pi pi-user p-mr-2"></i>
                        <b>Dados do usuário</b>
                    </div>
                </Divider>
                <form onSubmit={(e)=> { e.preventDefault(); saveNewUser(); }}>
                    <div className="p-float-label">
                        <InputText value={nome} onChange={(e)=> {user.current[e.target.name] = e.target.value; setNome(e.target.value); }} required name="nome" id="nome"/>
                        <label htmlFor="nome">Nome</label>
                    </div>
                    <div className="p-float-label">
                        <InputText value={email} onChange={(e)=>{  user.current[e.target.name] = e.target.value; setEmail(e.target.value); }} required name="email" id="email"/>
                        <label htmlFor="email">E-mail</label>
                    </div>
                    <div className="p-float-label">
                        <InputMask value={telefone} mask="(99) 99999-9999" pattern="[0-9]{11}" onChange={(e)=>{  user.current[e.target.name] = e.target.value; setTelefone(e.target.value); }} required type="tel" name="telefone" id="telefone"/>
                        <label htmlFor="telefone">Telefone</label>
                    </div>
                    <div className="p-float-label">
                        <InputMask value={celular} mask="(99) 99999-9999" pattern="[0-9]{11}" onChange={(e)=>{  user.current[e.target.name] = e.target.value; setCelular(e.target.value); }} required type="tel" name="celular" id="celular"/>
                        <label htmlFor="celular">Celular</label>
                    </div>
                    <div className="p-float-label">
                        <Dropdown name="nivel_acesso" value={nivelAcesso} options={careerList} onChange={(e) =>{ user.current[e.target.name] = e.target.value === 1 ? e.target.value - 1 : e.target.value;  setNivelAcesso(e.target.value); }} />
                        <label htmlFor="nivel_acesso">Profissional</label>
                    </div>
                    <div className="p-float-label">
                         <Dropdown name="inativo" value={inativo} options={[{label: 'Ativo', value: 1}, {label: 'Inativo', value: 2}]} onChange={(e) =>{ user.current[e.target.name] = e.target.value - 1; setInativo(e.target.value); }} />
                        <label htmlFor="status">Status</label>
                    </div>
                    <div className="p-float-label">
                        <Password value={senha} toggleMask feedback={false} onChange={(e)=>{  user.current[e.target.name] = e.target.value; setSenha(e.target.value); }} required  name="senha" id="senha"/>
                        <label htmlFor="senha">Senha</label>
                    </div>
                    <div className="p-float-label">
                        <Password value={confirmarSenha} feedback={false} toggleMask onChange={(e)=>{  user.current[e.target.name] = e.target.value; setConfirmarSenha(e.target.value); }} required name="confirmsenha" id="confirmsenha"/>
                        <label htmlFor="confirmsenha">Confirmar</label>
                    </div>
                    <div className="rules">
                        <Divider align="left">
                            <div className="p-d-inline-flex p-ai-center">
                                <i className="pi pi-lock p-mr-2"></i>
                                <b>Acessos</b>
                            </div>
                        </Divider>
                        <div className="area-admin">
                            <div>
                                Adminstrador do sistema? 
                            </div>
                            <div>
                            <ToggleButton checked={isAdmin}  onLabel="Sim" offLabel="Não"  name="administrador" id="administrador" onChange={(e) => {rules.current.administrador = isAdmin ? 1 : 0;  setIsAdmin(!isAdmin); }} />
                                {/* <input  onChange={(e)=>{ setIsAdmin(e.currentTarget.checked); rules.current.administrador = e.currentTarget.checked ? 1 : 0;  }} type="checkbox" name="administrador" id="administrador"/> */}
                            </div>
                        </div>
                        <div className={`table ${isAdmin ? 'block' : ''}`}>
                            <div className="header">
                                <div>Módulo</div>
                                <div>Área</div>
                                <div>Nenhum</div>
                                <div>Leitura</div>
                                <div>Edição</div>
                            </div>
                            <div className='list-rules'>
                            <ul>
                            {
                                Object.keys(rulesUser).map(rule => {
                                    return (<>
                                        
                                        {
                                            rulesUser[rule].data.map(module => {
                                                return (
                                                    <li>
                                                        <div>
                                                            {rulesUser[rule].name}
                                                        </div>
                                                        <div>
                                                            {module.name}
                                                        </div>
                                                        <div className="options-rules">
                                                            {
                                                                module.data.map((options, id) => {
                                                                    return (
                                                                        <span>
                                                                            {
                                                                                id === 0 ?
                                                                                    <input onChange={(e)=>{ rules.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} defaultChecked type="radio" name={module.ref} id={`${module.ref}-${options.name}`} value={options.data}/> 
                                                                                :
                                                                                    <input onChange={(e)=>{ rules.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} type="radio" name={module.ref} id={`${module.ref}-${options.name}`} value={options.data}/> 
                                                                            }
                                                                            <label htmlFor={`${module.ref}-${options.name}`}>
                                                                                {options.name}
                                                                            </label>
                                                                        </span>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </>)
                                })
                            }
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div className="area-buttons">
                        <Button onClick={
                            ()=> {
                                props.history.push('/User/Users/');
                            }
                        } label="Cancelar" className="p-button-danger" icon="pi pi-times" />
                        <Button onClick={()=>{ validadeNewUser(); }} label="Cadastar" className="p-button-info p-mr-2" icon="pi pi-save" />
                             {/* <NavLink className='button' to={`/Users/`}>
                                Cancelar
                            </NavLink>
                            <button onClick={()=>{
                                validadeNewUser();
                            }}>
                                Cadastrar
                            </button> */}
                    </div>
                </form>
            </div>
        </div>
    )

}

export default PageUserUsersNew;