const URL_BASE = 'http://localhost/wa2disksistemas/api/';
//const URL_BASE = 'http://192.168.15.45/wa2disksistemas/api/';

export const getDataByCPF = async (cpf) => {

    const header = new Headers();

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_BASE}Utils/getCPFData`, params);
    const text = await request.text();

    const html = new DOMParser().parseFromString(text, 'text/html');

    const token = html.querySelector('.g-recaptcha').getAttribute('data-sitekey');

    const requesttoken = await fetch(`${URL_BASE}Utils/getCaptcha/${token}`, params);
    const json = await requesttoken.text();

    return json;

}

export const getDataByCNPJ = async (cnpj) => {

    const header = new Headers();

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`https://receitaws.com.br/v1/cnpj/${cnpj}`, params);
    const json = await request.json();

    return json;

}

export const getDataByCEP = async (cep) => {

    const header = new Headers();

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`https://viacep.com.br/ws/${cep}/json/`, params);
    const json = await request.json();

    return json;

}