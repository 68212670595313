import React, {useEffect, useState} from 'react';

//import {Icon} from 'react-icons-kit';
//import {userPlus} from 'react-icons-kit/fa/userPlus';
// import {eye} from 'react-icons-kit/fa/eye';
// import {pencil} from 'react-icons-kit/fa/pencil';
// import {buildingO} from 'react-icons-kit/fa/buildingO';
// import {user} from 'react-icons-kit/fa/user';

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';

import {getClientList} from '../../../services/api/disksistemas';

import '../../../assets/style/page/user/client/index.scss';
import { NavLink } from 'react-router-dom';

const PageUserClient = (props) => {

    //console.log('#',props);

    
    const [listClients, setlistClients] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    
    useEffect(()=> {
        
        props.onSetpage('Clientes');
        
        const getData = async () => {
            const data = await getClientList();
            console.log(data);
            setlistClients(data.data);
        };
        
        getData();
        

    },[props]);

    return(
        <div className='page-user-client'>            
            
            <Toolbar className="bar-add"  right={()=>  <NavLink  to={`/Client/New/`}><Button label="Adicionar cliente" icon="pi pi-plus" iconPos="left" /></NavLink> } />
            <Toolbar className="bar-add"  right={()=> <span className="p-input-icon-left"><i className="pi pi-search" /><InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busca..." /></span> } />
            <div className="datatable-responsive-demo">
                <DataTable globalFilter={globalFilter} value={listClients} paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="p-datatable-sm p-datatable-responsive-demo p-datatable-striped">
                    <Column style={{width:'5%'}} field="id" header="ID"></Column>
                    <Column field={(client) => client.nome_fantasia || client.razao_social } header="Nome"></Column>
                    <Column field="cnpj_cpf" header="CPF/ CNPJ"></Column>
                    <Column style={{width:'8%', minWidth: '100px'}} field="ativo" header="Status" body={(client) =>
                        <>
                            <Tag value={client.ativo === '1' ? 'Ativo' : 'Inativo'} severity={client.ativo === '1' ? 'success' : 'danger'}></Tag>
                        </>
                    }></Column>
                    <Column style={{width:'12%', minWidth: '200px'}} className="area-buttons" body={(client)=> <>
                        <NavLink  to={`/Client/Edit/${client.id}`}>
                            <Button className="p-button-rounded p-button-warning p-mr-2" icon="pi pi-pencil" />
                        </NavLink>
                        <NavLink  to={`/Client/View/${client.id}`}>
                            <Button className="p-button-rounded p-button-info p-mr-2" icon="pi pi-eye" />
                        </NavLink>
                    </>
                    }></Column>
                </DataTable>
            </div>
            
        </div>
    )

}

export default PageUserClient;