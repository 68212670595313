import React, {useEffect, useState, useRef} from 'react';

import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { PickList } from 'primereact/picklist';
//import { InputText } from 'primereact/inputtext';
// import { InputMask } from 'primereact/inputmask';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';

import PageUserClientNew from '../../client/new';

import {getClientList, getAddressById, getContactById, servicesGetAll, finaceMethodPaymentGetAll, createBudget, createBudgetService, getBudgetById} from '../../../../services/api/disksistemas';

import { getToken } from '../../../../services/auth';

import '../../../../assets/style/page/user/budget/new/index.scss';

const PageUserBudgetEdit = (props) => {

    addLocale('br', {
        firstDayOfWeek: 1,
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['JAN', 'FEB', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
        today: 'Hoje',
        clear: 'Limpar'
    });


    const objectData = useRef();

    const modelObject = {
        id_client: null,
        id_endereco: null,
        visita_criar_orcamento: null,
        data_agendamento: null,
        contato: null, 
        telefone: null,
        ramal: null,
        celular: null,
        email: null,
        observacoes: null,
        valor: null,
        qtd_parcelas: null,
        primeiro_pagamento: null,
        forma_pagamento: null,
        condicao_pagamento: null,
        garantia: null, 
        validade_proposta: null,
        data_proposta: null,
        status: null,
        criado_por: null
    }

    objectData.current = objectData.current ? objectData.current : modelObject;

    const [clientList, setClientList] = useState([]);
    const [client, setClient] = useState(null);
    const [showNewClient, setShowNewCliente] = useState(false);

    const [contactList, setContactList] = useState([]);
    const [contact, setContact] = useState(null);
    const [showNewContact, setShowNewContact] = useState(false);

    const [addressList, setAddressList] = useState([]);
    const [address, setAddress] = useState(null);
    const [showNewAddress, setShowNewAddress] = useState(false);

    const [price, setPrice] = useState(null);
    const [paymentList, setPaymentList] = useState([]);
                                                
    const [payment, setPayment] = useState(null);
    const [conditionpaymentList, setConditionPaymentList] = useState([]);
    const [garantyList, setGarantyList] = useState([])
    const [garanty, setGaranty] = useState(null)
    const [conditionpayment, setConditionPayment] = useState(null);
    const [divison, setDivison] = useState(null);
    const [firstPayment, setFirstPayment] = useState(null);
    const [endBudget, setEndBudget] = useState(null);

    const [servicesList, setServicesList] = useState([])

    const [servicesSelected, setServicesSelected] = useState([]);

    const [showDialogDescription, setShowDialogDescription] = useState(false);
    const [contentDescription, setContentDescription] = useState(null);

    const [showDialogDescriptionPayment, setShowDialogDescriptionPayment] = useState(false);
    const [contentDescriptionPayment, setContentDescriptionPayment] = useState(null);

    const [showShedule, setShowShedule] = useState(1);
    const [shedule, setShedule] = useState(null);
    const [sheduleList, setSheduleList] = useState([]);

    const[showErros, setShowErros] = useState(false);
    const[errosData, setErrosData] = useState('');

    const [note, setNote] = useState('');


    useEffect(() => {
        
       // props.onSetpage('Cadastro orçamento');


    }, [props])

    useEffect(()=>{

        

        const getData = async () => {

            const dataBudget =  await getBudgetById(props.match.params.id);
            console.log(dataBudget.data);

            const clients = await getClientList();
            const dataList = clients.data.map(item => ({label: item.razao_social, code: item.id, ...item}));
            
            setClientList(dataList);

            const currentClient = dataList.filter(item => ~~item.code === ~~dataBudget.data.id_cliente);
            setClient(currentClient[0].code);


            const servicesData  = await servicesGetAll();
            setServicesList(servicesData.data);


            const contact = await getContactById(~~currentClient[0].code);
            const dataListContact = contact.data.map(item => ({
                label: `${item.nome}${item.email.length > 0 ? ' - ' + item.email : ''}${item.telefone.length > 0 ? ' - ' + item.telefone : ''}${item.ramal.length > 0 ? ' - ' + item.ramal : ''}${item.celular.length > 0 ? ' - ' + item.celular : ''}`, 
                code: item.id, ...item}));
            setContactList(dataListContact);

            const addressData = await getAddressById(~~currentClient[0].code);
            const dataAddressList = addressData.data.map(item => ({
                label: `${item.logradouro} ${item.numero}${item.complemento.length > 0 ? ' ' + item.complemento : ''} - ${item.bairro}, ${item.cidade} - ${item.UF}`, 
                code: item.id, ...item}));
            setAddressList(dataAddressList);

            const financesMethods = await finaceMethodPaymentGetAll();
            const finacesData =  financesMethods.data.map(item => {
                return {
                    label: item.forma_pagamento,
                    value: item.id,
                    description: item.descricao
                }
            })
            
            setPaymentList(finacesData);

            setConditionPaymentList([
                {label: 'A Vista', value: 'A Vista'},
                {label: 'A Prazo', value: 'A Prazo'},
                {label: 'Parcelado', value: 'Parcelado'}
            ]);

            setGarantyList([
                {label: 'Sem garantia', value: 1},
                {label: '30 dias', value: 2},
                {label: '90 dias', value: 3},
                {label: '180 dias', value: 4},
                {label: '1 ano', value: 5}
            ]);

            setSheduleList([
                {label: 'Sim', value: 1},
                {label: 'Não', value: 2}
            ]);

            props.onSetpage('Cadastro orçamento');

            setShowShedule(dataBudget.data.visita_criar_orcamento === 's' ? 1 : 2);
            setShedule(new Date(dataBudget.data.data_agendamento));
            setServicesList(servicesList.filter(item => (dataBudget.data.services.filter(_in => item.id === _in.id)).length === 0 ));
            setServicesSelected(servicesList.filter(item => (dataBudget.data.services.filter(_in => item.id === _in.id)).length > 0 ));
            setNote(dataBudget.data.observacoes);
            setFirstPayment(new Date(dataBudget.data.primeiro_pagamento));

        }

        getData();

    }, [props, servicesList]);

    useEffect(()=>{
        
        const getData = async () => {
            if(client){
                const contact = await getContactById(~~client.code);
                const dataList = contact.data.map(item => ({
                    label: `${item.nome}${item.email.length > 0 ? ' - ' + item.email : ''}${item.telefone.length > 0 ? ' - ' + item.telefone : ''}${item.ramal.length > 0 ? ' - ' + item.ramal : ''}${item.celular.length > 0 ? ' - ' + item.celular : ''}`, 
                    code: item.id, ...item}));
                setContactList(dataList);

                const addressData = await getAddressById(~~client.code);
                const dataAddressList = addressData.data.map(item => ({
                    label: `${item.logradouro} ${item.numero}${item.complemento.length > 0 ? ' ' + item.complemento : ''} - ${item.bairro}, ${item.cidade} - ${item.UF}`, 
                    code: item.id, ...item}));
                setAddressList(dataAddressList);


            }
        }

        getData();

    }, [client, setClientList]);

    // const getSelectedContact = (_contact) => {
    //     let c = '';
    //     if(_contact){
    //         const cTemp = contactList.filter(item => ~~item.id === ~~_contact.code);
    //         if(cTemp.length > 0)
    //             c = <p>({cTemp[0].tipo_contato}) - {cTemp[0].nome}, {cTemp[0].email} - {cTemp[0].telefone}-{cTemp[0].ramal} / {cTemp[0].celular}</p>;
    //     }else{
    //         c = <></>;
    //     }
    //     return c ;
    // }

    // const getSelectedAddress = (_address) => {
    //     let c = '';
    //     if(_address){
    //         const cTemp = addressList.filter(item => ~~item.id === ~~_address.code);
    //         if(cTemp.length > 0)
    //             c = <p>({cTemp[0].tipo_endereco}) - {cTemp[0].logradouro}, {cTemp[0].numero} - {cTemp[0].complemento} - {cTemp[0].bairro} - {cTemp[0].cidade} - {cTemp[0].uf} - {cTemp[0].cep}<br/>{cTemp[0].observacoes}</p>;
    //     }else{
    //         c = <></>;
    //     }
    //     return c ;
    // }

    const showDescription = (_data) => {
        setContentDescription(<><h2>{_data.servico}</h2><p>{_data.descricao}</p></>);
        setShowDialogDescription(true);

    }

    const showDescriptionPayment = (_data) => {

        const payment = paymentList.filter(item => ~~item.value === ~~_data)[0];
        setContentDescriptionPayment(<><h2>{payment.label}</h2><p>{payment.description}</p></>);
        setShowDialogDescriptionPayment(true);
    }


    const validateForm = () => {
        
        const erros = [];

        if(!client)
            erros.push('Selecione um cliente.');
        
        if(!contact)
            erros.push('Selecione um contato.');

        if(!address)
            erros.push('Selecione um endereço.');

        if(~~showShedule === 1 && !shedule)
            erros.push('Selecione a data do agendamento');

        if(~~servicesSelected.length === 0)
            erros.push('Serviço não selecionado');

        if(!payment)
            erros.push('Selecione a forma de pagamento');
        
        if(!conditionpayment)
            erros.push('Selecione a condição de pagamento');
        
        if(!price)
            erros.push('Preencha o preço');

        if(!divison)
            erros.push('Preencha as parcelas');
        
        if(!firstPayment)
            erros.push('Preencha o primeiro pagamento');

        if(!endBudget)
            erros.push('Preencha a validade da proposta');

        if(erros.length > 0){
            console.log(erros);
            setErrosData(<ul>
                {
                    erros.map((item, id)=> <li key={`key-${id}`}>{item}</li>)
                }
            </ul>);
            setShowErros(true);
        }

    }

    const saveData = async () => {
        
        console.log('Save Data');

       const date = new Date();
       const _garanty = garantyList.filter(item => item.value === garanty);
       const _payment = paymentList.filter(item => item.value === payment);

        let data = {
            id_cliente: client.id,
            id_endereco: address.id,
            visita_criar_orcamento: showShedule === 1 ? 's' : 'n',
            data_agendamento: shedule,
            contato: contact.nome,
            telefone: contact.telefone,
            ramal: contact.ramal,
            celular: contact.celular,
            email: contact.email,
            observacoes: note,
            valor: price,
            qtd_parcelas: divison,
            primeiro_pagamento: firstPayment,
            forma_pagamento: _payment[0].label,
            condicao_pagamento: conditionpayment,
            garantia: _garanty[0].label,
            validade_proposta: endBudget,
            data_proposta: `${date.toISOString().split('T')[0]} ${date.toTimeString().split(' ')[0]}`,
            status: 'Aguardando Aprovação',
            criado_por: JSON.parse(atob(getToken())).nome

        }

        const result = await createBudget(data);

        const listServices = servicesSelected.map(item => createBudgetService({id_orcamento: ~~result.data, id_servico: ~~item.id}));

        await Promise.all(listServices); 

    }

    return (
        <div className="page-user-budget-new">
             <form onSubmit={(e)=> { e.preventDefault(); saveData(); }}>
                <Divider className="area-client" align="left">
                    <div className="p-d-inline-flex p-ai-center">
                        <i className="pi pi-user p-mr-2"></i>
                        <b>Dados Cliente</b>
                    </div>
                </Divider>
                <div className="p-grid">
                    <div className="p-col">
                        <div className="p-float-label">
                            <Dropdown className="client" required optionField="label" optionValue="code" value={client} options={clientList} onChange={(e) => { setClient(e.target.value); setContact(null); setAddress(null); }} />
                            <label htmlFor="status">Cliente</label>
                            <Button style={{width: '170px'}} onClick={()=>{ setShowNewCliente(true); }} label="Adicionar cliente" className="p-ml-2"  icon="pi pi-plus" iconPos="left" />

                        </div>
                    </div>
                </div>
                <div className="p-grid p-mt-2">
                    <div className="p-col">
                        <div className="p-float-label">
                            <Dropdown className="contact" required disabled={!isNaN(client)} optionField="label" optionValue="code"  value={contact} options={contactList} onChange={(e) => { setContact(e.target.value) }} />
                            <label htmlFor="status">Contato</label>
                            <Button style={{width: '170px'}} onClick={()=>{ setShowNewContact(true); }} label="Adicionar contato" className="p-ml-2"  icon="pi pi-plus" iconPos="left" />
                            
                        </div>
                    </div>
                </div>
                <div className="p-grid p-mt-2 p-mb-2">
                    <div className="p-col">
                        <div className="p-float-label">
                            <Dropdown className="address" required disabled={!isNaN(client)} optionField="label" optionValue="code"  value={address} options={addressList} onChange={(e) => { setAddress(e.target.value) }} />
                            <label htmlFor="status">Endereço</label>
                            <Button style={{width: '170px'}} onClick={()=>{ setShowNewAddress(true); }} label="Adicionar endereço" className="p-ml-2"  icon="pi pi-plus" iconPos="left" />
                            
                        </div>
                    </div>
                </div>
                <Divider className="area-client" align="left">
                    <div className="p-d-inline-flex p-ai-center">
                        <i className="pi pi-list p-mr-2"></i>
                        <b>Serviços</b>
                    </div>
                </Divider>
                <div className="p-grid p-mb-2">
                    <div className="p-col-6">
                        <div className="p-float-label ">
                            <Dropdown required id="shedule" value={showShedule} options={sheduleList} onChange={(e) => setShowShedule(e.target.value)} />
                            <label htmlFor="shedule">Haverá visita para criar o orçamento?</label>
                        </div>
                    </div>
                    <div className="p-col-6">
                        <div className="p-float-label">
                            <Calendar required={~~showShedule !== 1} disabled={~~showShedule !== 1} dateFormat="dd/mm/yy" locale="br" id="shduletime" value={shedule} onChange={(e) => setShedule(e.target.value)} showIcon showTime />
                            <label htmlFor="sheduletime">Data agendamento</label>
                        </div>
                    </div>
                </div>

                <div className="p-grid">
                    <div className="p-col">
                        <PickList required sourceHeader="Disponíveis" targetHeader="Selecionados" source={servicesList} target={servicesSelected} 
                        itemTemplate={(row)=> (
                            <div className="p-grid">
                                <div className="p-col">
                                    {row.id} - {row.servico}
                                </div>
                                <div className="p-col-1">
                                    <Button onClick={()=>{
                                        showDescription(row)
                                    }} icon="pi pi-info" className="p-button-rounded p-button-warning p-button-sm" />
                                </div>
                            </div>)}
                        sourceStyle={{ height: '342px' }} targetStyle={{ height: '342px' }}
                        showSourceControls={false}
                        showTargetControls={false}
                        onChange={(e)=>{
                            setServicesList(e.source);
                            setServicesSelected(e.target);
                        }}></PickList>
                        
                    </div>
                </div>

                <div className="p-grid p-mt-3">
                    <div className="p-col">
                        <div className="p-float-label ">
                            <InputTextarea autoResize style={{width: '100%'}} id="note" value={note} onChange={(e) => setNote(e.target.value)} rows={3} />
                            <label htmlFor="note">Observações</label>
                        </div>
                    </div>
                </div>


                <Divider className="area-client" align="left">
                    <div className="p-d-inline-flex p-ai-center">
                        <i className="pi pi-dollar p-mr-2"></i>
                        <b>Dados finânceiros</b>
                    </div>
                </Divider>

                <div className="p-grid">
                    <div className="p-col">
                        <div className="p-formgroup-inline">
                            <div style={{width:`calc(100% - 50px)`}} className="p-field">
                                <div className="p-float-label ">
                                    <Dropdown required id="payment" value={payment} options={paymentList} onChange={(e) => setPayment(e.target.value)} />
                                    <label htmlFor="payment">Forma de pagamento</label>
                                </div>
                            </div>
                            <div className="p-field">
                                <Button disabled={payment === null} onClick={()=>{
                                        showDescriptionPayment(payment)
                                    }} icon="pi pi-info" className="p-button-rounded p-button-warning p-button-sm" />
                            </div>
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="p-float-label">
                            <Dropdown required id="conditionpayment" value={conditionpayment} options={conditionpaymentList} onChange={(e) => setConditionPayment(e.target.value)} />
                            <label htmlFor="conditionpayment">Condição de pagamento</label>
                        </div>
                    </div>
                </div>

                <div className="p-grid">
                    <div className="p-col-2">
                        <div className="p-field">
                            <div className="p-float-label">
                                <InputNumber required value={price} min={0} onValueChange={(e) => setPrice(e.target.value)} mode="decimal" locale="pt-BR" minFractionDigits={2}/>
                                <label htmlFor="status">Preço</label>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-2">
                        <div className="p-field">
                            <div className="p-float-label">
                                <InputNumber required value={divison} min={0} onValueChange={(e) => setDivison(e.target.value)}  locale="pt-BR" />
                                <label htmlFor="status">Parcelas</label>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-2">
                        <div className="p-field">
                            <div className="p-float-label">
                                <Dropdown required id="conditionpayment" value={garanty} options={garantyList} onChange={(e) => setGaranty(e.target.value)} />
                                <label htmlFor="conditionpayment">Garantia</label>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-2">
                        <div className="p-field">
                            <div className="p-float-label">
                                <Calendar required  dateFormat="dd/mm/yy" locale="br" id="firstpayment" key="firstpayment" value={firstPayment} onChange={(e) => setFirstPayment(e.target.value)} showIcon />
                                <label htmlFor="firstpayment">Primeiro pagamento</label>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-2">
                        <div className="p-field">
                            <div className="p-float-label">
                                <Calendar required  dateFormat="dd/mm/yy" locale="br" id="endbudget" key="endbudget" value={endBudget} onChange={(e) => setEndBudget(e.target.value)} showIcon />
                                <label htmlFor="endbudget">Validade da Proposta</label>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-2">
                        <div style={{display:'flex'}} className="p-col p-justify-end p-align-end">
                            <Button onClick={()=>{
                                validateForm();
                            }} label="Salvar" icon="pi pi-save" />
                        </div>
                    </div>
                </div>

             </form>
            
             <Dialog  maximizable modal header={`Erros`} visible={showErros} style={{ width: '50vw' }}  onHide={() => setShowErros(false)}>
                {errosData}
            </Dialog>

             <Dialog  maximizable modal header={`Cadastro cliente`} visible={showNewClient} style={{ width: '50vw' }}  onHide={() => setShowNewCliente(false)}>
                <PageUserClientNew  onSetpage={()=> null} />
            </Dialog>

             <Dialog  maximizable modal header={`Cadastro contato`} visible={showNewContact} style={{ width: '50vw' }}  onHide={() => setShowNewContact(false)}>
                {/* <PageUserClientNew  onSetpage={()=> null} /> */}
            </Dialog>

             <Dialog  maximizable modal header={`Cadastro endereço`} visible={showNewAddress} style={{ width: '50vw' }}  onHide={() => setShowNewAddress(false)}>
                {/* <PageUserClientNew  onSetpage={()=> null} /> */}
            </Dialog>

             <Dialog  maximizable modal header={`Descrição`} visible={showDialogDescription} style={{ width: '50vw' }}  onHide={() => setShowDialogDescription(false)}>
                    {contentDescription}
            </Dialog>

             <Dialog  maximizable modal header={`Descrição`} visible={showDialogDescriptionPayment} style={{ width: '50vw' }}  onHide={() => setShowDialogDescriptionPayment(false)}>
                {contentDescriptionPayment}
            </Dialog>

        </div>
    )

}

export default PageUserBudgetEdit;