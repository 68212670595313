import React from 'react';

const ContactView =  (props) => {

    return (
        <div className="contact-view">
            <div>{props.contactdata.tipo_contato}</div>
            <div>{props.contactdata.nome} {props.contactdata.email}, {props.contactdata.celular} - {props.contactdata.telefone} - {props.contactdata.ramal}</div> 
        </div>
    )

}

export default ContactView;