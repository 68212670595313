import React, {useState, useEffect, createElement} from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';

import FormAddress from '../../../../../components/address/form';
import AddressView from '../../../../../components/address/view';
import UIPrealoder from '../../../../../components/ui/preloader';

import {getAddressById, deleteAddress} from '../../../../../services/api/disksistemas/index';

const ClientEditAddress = (props) => {

    const [listAddress, setAddressList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState('');

    useEffect(()=>{
        
        const getData = async () => {
            const data = await getAddressById(props.match.params.id);
            console.log(data.data);
            setAddressList(data.data);
        }

        getData();

    }, [props.match.params.id]);

    const closeContent = (_bl) => {
        setContentModal('');
        setShowModal(_bl);
    }

    const createNewAddress = () => {
        const elem = createElement(FormAddress, { onClose: closeContent, id_cliente: props.match.params.id, onUpdate: updateList, ...props});
        setContentModal(elem);
        setShowModal(true);
    }

    const viewAddress = (id) => {

        const data = listAddress.filter(item => item.id === id)[0];

        const elem = createElement(AddressView, { onClose: closeContent, onUpdate: updateList, addressdata: data, ...props});
        setContentModal(elem);
        setShowModal(true);
    }

    const editAddress = (id) => {

        const data = listAddress.filter(item => item.id === id)[0];

        const elem = createElement(FormAddress, { onClose: closeContent, onUpdate: updateList, addressdata: data, ...props});
        setContentModal(elem);
        setShowModal(true);
    }

    const deleteAddressExecute = async (id) => {

        confirmDialog({
            message: 'Tem certeza que deseja apagar o registro?',
            header: 'Atenção',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                await deleteAddress({id: id});
                updateList();
            },
            reject: () => {}
        });

    }

    const updateList = async () => {
        const data = await getAddressById(props.match.params.id);
       // console.log(data.data);
        setShowModal(false);
        setContentModal('');
        setAddressList(data.data);
    }

    return(
        <div class="client-edit-address">
           
            {!listAddress ? <UIPrealoder /> : ''}
            
            <Toolbar className="bar-add"  right={()=>  <Button onClick={() => { createNewAddress() }} label="Adicionar endereço" icon="pi pi-plus" iconPos="left" /> } />

            <DataTable value={listAddress} paginator={listAddress?.length > 10} rows={10} rowsPerPageOptions={[5, 10, 25]} className="p-datatable-sm p-datatable-responsive-demo p-datatable-striped">
                <Column style={{width: '10rem'}} field="tipo_endereco" header="Tipo"></Column>
                <Column body={
                    (_address)=> <div>{_address.logradouro} {_address.numero} {_address.complemento.length > 0 ? '- ' + _address.complemento : ''}, {_address.bairro} - {_address.cidade} - {_address.UF}</div> 
                } header="Endereço"></Column>
                <Column style={{width:'12%', minWidth: '200px'}} className="area-buttons" body={(row)=> <>
                        <Button onClick={()=>{ viewAddress(row.id) }} className="p-button-rounded p-button-info p-mr-2" icon="pi pi-eye" />
                        <Button onClick={()=>{ editAddress(row.id) }} className="p-button-rounded p-button-warning p-mr-2" icon="pi pi-pencil" />
                        <Button  onClick={() => {deleteAddressExecute(row.id)}}  className="p-button-rounded p-button-danger p-mr-2" icon="pi pi-trash" />
                    </>
                    }></Column>
            </DataTable>
            <Dialog visible={showModal} style={{ width: '50vw' }} onHide={() => setShowModal(false)}>
                {contentModal}
            </Dialog>

        </div>
    )

}

export default ClientEditAddress;