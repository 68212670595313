import React, {useEffect, useState} from 'react';

import { Divider } from 'primereact/divider';

import {getBudgetById} from '../../services/api/disksistemas';

const ResumeBudget = (props) => {

    const [budget, setBudget] = useState(null);

    useEffect(() => {

        const  getData = async () => {
            const data = await getBudgetById(props.budgetid);
            setBudget(data.data);
            console.log(data.data);
        }

        getData();

        
    }, [props])

    return (
        <div className="resume-budget">

            <h3>
                <strong>Responsável</strong>: {budget?.criado_por}
            </h3>

            <Divider align="left">
                <div className="p-d-inline-flex p-ai-center">
                    <i className="pi pi-user p-mr-2"></i>
                    <b>Dados</b>
                </div>
            </Divider>
            <div><strong>Cliente</strong>: {budget?.cliente}</div>
            <div><strong>Endereço</strong>: {budget?.logradouro}, {budget?.numero} - {budget?.complemento} - {budget?.cidade} - {budget?.uf} - {budget?.cep} <br /> {budget?.ponto_referencia}</div>
            <div><strong>Contato</strong>: {budget?.contato} - {budget?.email} - {budget?.telefone || ''} - {budget?.ramal || ''} / {budget?.celular || ''} </div>
            <Divider align="left">
                <div className="p-d-inline-flex p-ai-center">
                    <i className="pi pi-list p-mr-2"></i>
                    <b>Serviços</b>
                </div>
            </Divider>
            <div><strong>Agendamento</strong>: {new Date(budget?.data_agendamento).toLocaleDateString() + ' - ' + new Date(budget?.data_agendamento).toLocaleTimeString()}</div>
            <div><strong>Criar orçamento na visita?</strong> {budget?.visita_criar_orcamento === 's' ? 'Sim' : 'Não'}</div>
            <ul>
                {
                    (budget?.services||[]).map(item => {
                        return <li>{item.servico}</li>
                    })
                }
            </ul>
            <Divider align="left">
                <div className="p-d-inline-flex p-ai-center">
                    <i className="pi pi-dollar p-mr-2"></i>
                    <b>Financeiro</b>
                </div>
            </Divider>
            <div><strong>Data proposta</strong>: {new Date(budget?.data_proposta).toLocaleDateString() + ' - ' + new Date(budget?.data_proposta).toLocaleTimeString()}</div>
            <div><strong>Validade proposta</strong>: {new Date(budget?.validade_proposta).toLocaleDateString()}</div>
            <div><strong>Garantia</strong>: {budget?.garantia}</div>
            <div><strong>Status</strong>: {budget?.status}</div>
            <div><strong>Valor</strong>: {budget?.qtd_parcelas} X {budget?.valor} - {budget?.condicao_pagamento}</div>
            <div><strong>Forma pagamento</strong>: {budget?.forma_pagamento}</div>
            <div><strong>Primeiro pagamento</strong>: {new Date(budget?.validade_proposta).toLocaleDateString()}</div>
            <Divider align="left">
                <div className="p-d-inline-flex p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-2"></i>
                    <b>Observações</b>
                </div>
            </Divider>
            <div>{budget?.observacoes}</div>
            
        </div>
    )

}

export default ResumeBudget;