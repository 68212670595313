import React, {useState, useEffect} from 'react';

import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import {getAddressById, getContactById} from '../../../../../services/api/disksistemas/index';

import UIPrealoder from '../../../../../components/ui/preloader';

const ClientViewUserData = (props) => {

    const isPeople = props.clientdata?.tipo_pessoa === 'F';
    const [listAddress, setAddressList] = useState(null);
    const [listContact, setContactList] = useState(null);

    useEffect(()=>{
        
        const getData = async () => {
            const data = await getAddressById(props.match.params.id);
            //console.log(data.data);
            setAddressList(data.data);

            const datac = await getContactById(props.match.params.id);
            //console.log(datac.data);
            setContactList(datac.data);
        }

        getData();

    }, [props.match.params.id]);

    return(
        <div className="client-view-user-data">
            <div className="user-data">
                <div className="document">
                
                    <strong>{ isPeople ? 'CPF: ' : 'CNPJ: '}</strong>{props.clientdata?.cnpj_cpf}
                </div>
                <div className="ativo">
                    <strong>Ativo: </strong>{props.clientdata?.Ativo ? 'Ativo' : 'Inativo'}
                </div>
                {
                    !isPeople ? 
                    <>
                        <div className="name_fantasy">
                            <strong>Nome Fantasia: </strong>{props.clientdata?.nome_fantasia}
                        </div>
                        <div className="ins_mun">
                            <strong>Inscrição Municipal: </strong>{props.clientdata?.insc_municipal}
                        </div>
                        <div className="ins_est">
                            <strong>Inscrição Estadual: </strong>{props.clientdata?.insc_estadual}
                        </div>  
                    </>
                        : 
                    ''
                }
                <div className="observacoes">
                   <div>
                        <strong>Observações: </strong>{props.clientdata?.observacoes}
                   </div>
                </div>
            </div>
            <Divider align="left">
                <div className="p-d-inline-flex p-ai-center">
                    <i className="pi pi-map-marker p-mr-2"></i>
                    <b>Endereço</b>
                </div>
            </Divider>
            {!listAddress ? <UIPrealoder /> : ''}
            
            <DataTable value={listAddress} paginator={listAddress?.length > 10} rows={10} rowsPerPageOptions={[5, 10, 25]} className="p-datatable-sm p-datatable-responsive-demo p-datatable-striped">
                <Column style={{width: '10rem'}} field="tipo_endereco" header="Tipo"></Column>
                <Column body={
                    (_address)=> <div>{_address.logradouro} {_address.numero} {_address.complemento.length > 0 ? '- ' + _address.complemento : ''}, {_address.bairro} - {_address.cidade} - {_address.UF}</div> 
                } header="Logradouro"></Column>
            </DataTable>
            <Divider align="left">
                <div className="p-d-inline-flex p-ai-center">
                    <i className="pi pi-phone p-mr-2"></i>
                    <b>Contatos</b>
                </div>
            </Divider>
            {!listContact ? <UIPrealoder /> : ''}
            
            <DataTable value={listContact} paginator={listContact?.length > 10} rows={10} rowsPerPageOptions={[5, 10, 25]} className="p-datatable-sm p-datatable-responsive-demo p-datatable-striped">
                <Column style={{width: '10rem'}} field="tipo_contato" header="Tipo"></Column>
                <Column body={
                    (_contact)=> <div>{_contact.nome} {_contact.email}, {_contact.celular} - {_contact.telefone} - {_contact.ramal}</div> 
                } header=""></Column>
            </DataTable>
        </div>
    )

}

export default ClientViewUserData;