import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import RoutePritvate from '../private';

import PageLogin from '../../../page/login';
import PageResetPassword from '../../../page/resetpassword';
import PageUser from '../../../page/user';

const MainRouter = () => {
    return(
        <BrowserRouter>
            <Switch>
                <RoutePritvate path="/User/:page?" component={PageUser} />
                <Route path="/ResetPassword/:token?" component={PageResetPassword} />
                <Route path="*" component={PageLogin} />
            </Switch>
        </BrowserRouter>
    )
}

export default MainRouter;