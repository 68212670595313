import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import {isAuthenticated} from '../../../services/auth';

const RoutePritvate = ({component: Component, ...rest}) => {
    return (
        <Route 
            {...rest}
            render={ props =>
                isAuthenticated() ?
                    (<Component {...props} />)
                :
                    (<Redirect to={{pathname:'', state:{from:props.location}}} />)
            }
        />
    )
}

export default RoutePritvate;