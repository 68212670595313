import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';

import { logout } from '../../services/auth';

import UserRouter from '../../components/navigation/user';

import {Icon} from 'react-icons-kit';
import {navicon} from 'react-icons-kit/fa/navicon';
import {thLarge} from 'react-icons-kit/fa/thLarge';
import {addressBookO} from 'react-icons-kit/fa/addressBookO';
// import {fileTextO} from 'react-icons-kit/fa/fileTextO';
// import {truck} from 'react-icons-kit/fa/truck';
import {listAlt} from 'react-icons-kit/fa/listAlt';
// import {ticket} from 'react-icons-kit/fa/ticket';
// import {calendar} from 'react-icons-kit/fa/calendar';
// import {th} from 'react-icons-kit/fa/th';
// import {archive} from 'react-icons-kit/fa/archive';
import {powerOff} from 'react-icons-kit/fa/powerOff';
// import {gear} from 'react-icons-kit/fa/gear';
// import {levelUp} from 'react-icons-kit/fa/levelUp';
// import {levelDown} from 'react-icons-kit/fa/levelDown';
// import {lineChart} from 'react-icons-kit/fa/lineChart';
import {users} from 'react-icons-kit/fa/users';

import logo from '../../assets/image/logo_b.png';
import logo_min from '../../assets/image/logo_b_m.png';

import '../../assets/style/page/user/index.scss';

const PageUser = (props) => {

    const [menuIsOpen, setMenuIsOpen] = useState(true);
    const [pageTitle, setPageTitle] = useState('');

    const setPageUpdate = (data) => {
        setPageTitle(data);
    }

    return(
        <div className='page-user'>
            <div className={`main-menu ${menuIsOpen ? '' : 'hide'}`}>
                <div className='area-logo'>
                    <img src={logo} alt='' />
                    <img src={logo_min} alt='' />
                    <div className='area-button-menu'>
                        <div onClick={()=>{ setMenuIsOpen(!menuIsOpen); }}><Icon icon={navicon} /></div>
                    </div>
                </div>
                <div className='area-menu'>
                    <ul>
                        <li>
                            <NavLink exact activeClassName='current' to="/User">
                                <div><Icon icon={thLarge} /></div>
                                <div>Dashboard</div>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink exact activeClassName='current' to="/User/Calendar">
                                <div><Icon icon={calendar} /></div>
                                <div>Agenda</div>
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink activeClassName='current' to="/User/Client">
                                <div><Icon icon={addressBookO} /></div>
                                <div>Clientes</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact activeClassName='current' to="/User/Budget">
                                <div><Icon icon={listAlt} /></div>
                                <div>Orçamentos</div>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink exact activeClassName='current' to="/User/Contract">
                                <div><Icon icon={fileTextO} /></div>
                                <div>Contratos</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact activeClassName='current' to="/User/Provider">
                                <div><Icon icon={truck} /></div>
                                <div>Fornecedores</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact activeClassName='current' to="/User/OS">
                                <div><Icon icon={ticket} /></div>
                                <div>Ordens de Serviço</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact activeClassName='current' to="/User/Products">
                                <div><Icon icon={archive} /></div>
                                <div>Produtos</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact activeClassName='current' to="/User/Stock">
                                <div><Icon icon={th} /></div>
                                <div>Estoque</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact activeClassName='current' to="/User/CountIn">
                                <div><Icon icon={levelUp} /></div>
                                <div>Contas a Receber</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact activeClassName='current' to="/User/CountOut">
                                <div><Icon icon={levelDown} /></div>
                                <div>Contas a Pagar</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact activeClassName='current' to="/User/Results">
                                <div><Icon icon={lineChart} /></div>
                                <div>Centro de Resultados</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact activeClassName='current' to="/User/Nature">
                                <div><Icon icon={th} /></div>
                                <div>Naturezas</div>
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink activeClassName='current' to={{pathname:"/User/Users", state:{currentpage:"/User/Users"}}}>
                                <div><Icon icon={users} /></div>
                                <div>Usuários</div>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink exact activeClassName='current' to="/User/Configuration">
                                <div><Icon icon={gear} /></div>
                                <div>Configurações</div>
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink onClick={()=> {logout();}} exact activeClassName='current' to="/">
                                <div><Icon icon={powerOff} /></div>
                                <div>Sair</div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
            <section data-currentpage={props.match.params.page}>
                <header>
                    <div>{pageTitle}</div>
                </header>
                <UserRouter onSetpage={setPageUpdate} currentpage={`${pageTitle}-${window.location.pathname.replace(/\//gi,'_')}`} {...props} />
            </section>
        </div>
    )
};

export default PageUser;
