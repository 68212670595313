export const careerList = [
    {label:'Administrativo', value: 1},
    {label:'Comercial', value: 2},
    {label:'Técnico', value: 3}
];

export const rulesUser = {
    services :{
        name:'Serviços',
        data:[
            {name: 'Lista Clientes', ref:'clientes', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Detalhes Clientes', ref:'detalhes_clientes', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Contratos', ref:'contratos', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Detalhes Contratos', ref:'detalhes_contratos', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Orçamentos', ref:'orcamentos', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Detalhes Orçamentos', ref:'detalhes_orcamentos', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Ordens de Serviço', ref:'ordens_servicos', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Detalhes Ordens de Serviço', ref:'detalhes_ordens_servicos', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Fornecedores', ref:'fornecedores', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Detalhes Fornecedores', ref:'detalhes_fornecedores', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Agenda', ref:'agenda', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
            ]}
        ]
    },
    stock :{
        name:'Estoque',
        data:[
            {name: 'Produtos', ref:'produtos', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Detalhes Produtos', ref:'detalhes_produtos', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Estoque', ref:'estoque', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Entrada Estoque', ref:'entradas_estoque', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]}
        ]
    },
    finance :{
        name:'Financeiro',
        data:[
            {name: 'Central de Resultados', ref:'finan_centro_resultados', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Detalhes Central de Resultados', ref:'finan_detalhes_centro_resultados', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Formas de Pagamento', ref:'finan_forma_pagamento', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Detalhes Formas de Pagamento', ref:'finan_detalhes_forma_pagamento', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Naturezas', ref:'finan_naturezas', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Detalhes Naturezas', ref:'finan_detalhes_naturezas', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Contas a Receber', ref:'finan_contas_receber', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Detalhes Contas a Receber', ref:'finan_detalhes_contas_receber', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Contas a Pagar', ref:'finan_contas_pagar', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Detalhes Contas a Pagar', ref:'finan_detalhes_contas_pagar', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]}
        ]
    },
    admim :{
        name:'Administrativo',
        data:[
            {name: 'Usuários', ref:'usuarios', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]},
            {name: 'Minha Empresa', ref:'minha_empresa', data:[
                {name:'Nenhum', data:0},
                {name:'Leitura', data:1},
                {name:'Edição', data:2},
            ]}
        ]
    }
}

export const estatesList = [
    {label:"AL", value:"AL"},
    {label:"AM", value:"AM"},
    {label:"AP", value:"AP"},
    {label:"AC", value:"AC"},
    {label:"BA", value:"BA"},
    {label:"CE", value:"CE"},
    {label:"DF", value:"DF"},
    {label:"ES", value:"ES"},
    {label:"GO", value:"GO"},
    {label:"MA", value:"MA"},
    {label:"MG", value:"MG"},
    {label:"MS", value:"MS"},
    {label:"MT", value:"MT"},
    {label:"PA", value:"PA"},
    {label:"PB", value:"PB"},
    {label:"PE", value:"PE"},
    {label:"PI", value:"PI"},
    {label:"PR", value:"PR"},
    {label:"RJ", value:"RJ"},
    {label:"RN", value:"RN"},
    {label:"RO", value:"RO"},
    {label:"RR", value:"RR"},
    {label:"RS", value:"RS"},
    {label:"SC", value:"SC"},
    {label:"SE", value:"SE"},
    {label:"SP", value:"SP"},
    {label:"TO", value:"TO"}
];