import React, {useState, useEffect, useRef} from 'react';
import {Icon} from 'react-icons-kit';
import {camera} from 'react-icons-kit/fa/camera';

import { Divider } from 'primereact/divider';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';

import {getUserById, getSecurityUserById} from '../../../../services/api/disksistemas';

import {careerList, rulesUser} from '../../../../services/data';

import '../../../../assets/style/page/user/users/view/index.scss';

const PageUserUsersView = (props) => {

    console.log(props);
    
    const [user, setUser] = useState(null);
    const rules  = useRef(null);
    
    const getDataCareer = (_id) => {
        const data = careerList.filter( item => {
            return item.value === _id;
        });
        
        return data[0];
    }
    
    const getDataSecurity = (base, id) => {
        const data = base.filter( item => {
            return item.data === id;
        });
        
        return data[0].name; 
    }
    
    useEffect(()=> {
        
        const getData = async () => {
            
            const r =  await getSecurityUserById(props.match.params.id);
            rules.current = r.data;
            
            const data  =  await getUserById(props.match.params.id);
            console.log(data.data);
            setUser(data.data);
            props.onSetpage('Visualizar Usuário');
            
            //setUser(data.data);
            
        }

        getData();

    }, [props]);

    return(
        <div className="page-user-users-view">
            <Toolbar className="bar-add"  right={()=> <Button onClick={()=>{props.history.push(`/Users/Edit/${props.match.params.id}`) }} label="Editar usuário" icon="pi pi-pencil" iconPos="left" /> } />
            <div className='card-user-data'>
                <div class="avatar">
                    <Icon icon={camera} />
                </div>
                <div class="userid">
                    {user?.id}
                </div>
                <div className='name'>
                    {user?.nome}
                </div>
                <div className='email'>
                    {user?.email}
                </div>
                <div className='phone'>
                    {user?.telefone}
                </div>
                <div className='smartphone'>
                    {user?.celular}
                </div>
                <div></div>
                <div className='career'>
                    {getDataCareer(~~user?.nivel_acesso)?.label}
                </div>
                <div className='status'>
                    {~~user?.inativo === 0 ? <span className="active">Ativo</span> : <span className="inative">Inativo</span> }
                </div>
            </div>
            <div className="rules">
                    <Divider align="left">
                        <div className="p-d-inline-flex p-ai-center">
                            <i className="pi pi-lock p-mr-2"></i>
                            <b>Acessos</b>
                        </div>
                    </Divider>
                    <p>É Adminstrador? {rules.current ? (~~rules.current.administrador === 1 ? 'Sim' : 'Não') : ''} </p>
                    <div className='list-rules'>
                        {
                            Object.keys(rulesUser).map((rule, rid) => {
                                return (<div key={`${rule}-${rid}`}>
                                    <strong>{rulesUser[rule].name}</strong>
                                    <ul>
                                    {
                                        rulesUser[rule].data.map((module, id) => {
                                            return (
                                                <li key={`${module.name}-${id}`}>
                                                    {module.name}:
                                                    <span>
                                                        {rules.current ? getDataSecurity(module.data, ~~rules.current[module.ref]) : ''}
                                                    </span>
                                                </li>
                                            )
                                        })
                                    }
                                    </ul>
                                </div>)
                            })
                        }
                    </div>
                </div>
        </div>
    )

}

export default PageUserUsersView;