import React, {useState, useEffect, useCallback, createElement, useRef} from 'react';

import { TabMenu } from 'primereact/tabmenu';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';

import ClientViewUserData from './userdata';
import ClientViewService from './service';

import {getClientById} from '../../../../services/api/disksistemas';


import '../../../../assets/style/page/user/client/view/index.scss';

const PageUserClientView = (props) => {
    
    const items = useRef([
        {label: 'Dados', icon: 'pi pi-fw pi-user', tabname: 'clientdata', id: 0},
        {label: 'Serviços', icon: 'pi pi-fw pi-list', tabname: 'service', id: 1}
    ]);
    
    const [user, setUser] = useState(null);
    const [navTab, setNavTab] = useState('clientdata');
    const [pageTab, setPageTab] = useState(null);
    const [pageTabId, setPageTabid] = useState('0');
    const [activeItem, setActiveItem] = useState(items.current[0]);


    const navigationTab = useCallback((_tab = null) => {
        setNavTab(_tab);
        let page;
        switch(_tab){
            case 'service':
                page = createElement(ClientViewService, {...props});
            break;
            default:
                page = createElement(ClientViewUserData, {clientdata: user, ...props});
        }
        
        //setPageTabid(new Date().getTime());
        setPageTab(page);
    }, [props, user])

    //const start = useCallback(() => navigationTab(), [null]);

    useEffect(()=>{

        navigationTab(navTab);

    },[navigationTab, navTab]);
    
    useEffect(()=> {
        
        const getData = async () => {
            
            const data  =  await getClientById(props.match.params.id);
            console.log(data.data);
            setUser(data.data);
            props.onSetpage('Visualizar Cliente');
            
        }

        getData();

    }, [props]);

    return(
        <div className="page-user-client-view">
            
            <Toolbar left={()=><div className="identifier-client">
                <strong>#{user?.id} - {user?.razao_social}</strong>
            </div>} right={()=>
            <>
               {
                    user?.id ?
                        <Button onClick={()=>{
                            props.history.push(`/Client/Edit/${props.match.params.id}`);
                        }} className="p-button p-mr-2" icon="pi pi-pencil" label="Editar" />
                    : ''
                }
            </>} />

            <TabMenu model={items.current} activeItem={activeItem} onTabChange={(e) =>{ setPageTabid(new Date().getTime()); console.log(e); setActiveItem(e.value); navigationTab(e.value.tabname);}}  />

            <div className="tab-content" key={`${navTab}-${pageTabId}`}>{pageTab}</div>
            <div id="modal-client"></div>  
        </div>
    )

}

export default PageUserClientView;