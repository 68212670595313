import React, {useState} from 'react';

import {login, isAuthenticated} from '../../services/auth';

import { Redirect } from 'react-router-dom';
import {executeLogin} from '../../services/api/disksistemas';

import logo from '../../assets/image/logo_b.png';

import '../../assets/style/page/login/index.scss';

const PageLogin = (props) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const submitLogin = async (e) => {

        const data = await executeLogin(email, password);


        console.log(data);

        if(data.data){
            login(btoa(JSON.stringify(data.data)));
            props.history.push('/User');
        }else{
            console.log('Error Usuario');
        }

    }

    if(isAuthenticated()){
        return (
            <Redirect to={{pathname:'/User', state:{from:props.location}}} />
        )
    }

    return (
        <div className="page-login">
            <div className="box-login">
                <div className="area-logo">
                    <img src={logo} alt=""/>
                </div>
                <div className="area-form">
                    <form onSubmit={(e)=> { e.preventDefault(); }}>
                        <div>
                            <input required type='email' maxLength={100} value={email} onInput={(e)=>{ setEmail(e.currentTarget.value) }} placeholder="E-mail" />
                        </div>
                        <div>
                            <input required type='password' maxLength={100} value={password} onInput={(e)=>{ setPassword(e.currentTarget.value) }} placeholder="Senha" />
                        </div>
                        <div>
                            <div onClick={()=>{ props.history.push('/ResetPassword'); }} className='link-resetpassword'>Esqueci minha senha</div>
                        </div>
                        <div>
                            <button onClick={()=> { submitLogin(); }}>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                Entrar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default PageLogin;