import React, {useRef, useState, useEffect} from 'react';

import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';

import {getClientById, updateClient} from '../../../../../services/api/disksistemas/index';

import {getDataByCNPJ} from '../../../../../services/api/utils';

const ClientEditUserData = (props) => {

    const [typerPerson, setTypePerson] = useState(null);
    const client = useRef();
    const [nome_fantasia, setNomeFantasia] = useState('');
    const [razao_social, setRazaoSocial] = useState('');
    const [cnpj_cpf, setCNPJCPF] = useState('');
    const [insc_estadual, setInscEstadual] = useState('');
    const [insc_municipal, setInscMunicipal] = useState('');
    const [observacoes, setObservacoes] = useState('');
    const [ativo, setAtivo] = useState('');

    const clientData = {
        tipo_pessoa: null,
        nome_fantasia: '',
        razao_social: null,
        cnpj_cpf: '',
        insc_estadual: '',
        insc_municipal: '', 
        data_cadastro: '',
        observacoes: '', 
        ativo: 1
    };

    client.current = client.current ? client.current : clientData;

    console.log(props);

    useEffect(()=>{
        
        const getData = async () => {
            const data = await getClientById(props.match.params.id);
            client.current =  data.data;

            setTypePerson(data.data.tipo_pessoa);
            setNomeFantasia(data.data.nome_fantasia);
            setRazaoSocial(data.data.razao_social)
            setCNPJCPF(data.data.cnpj_cpf);
            setInscMunicipal(data.data.insc_municipal);
            setInscEstadual(data.data.insc_estadual);
            setObservacoes(data.data.observacoes);
            setAtivo(~~data.data.ativo === 0 ? 2 : ~~data.data.ativo);
        }

        getData();

    }, [props.match.params.id]);

    const getDataPersonType = async () => {
        
        if(typerPerson === 'F' && client.current.cnpj_cpf.toString().replace(/\D/gi, '').length === 11){
            
        }else if(client.current.cnpj_cpf.toString().replace(/\D/gi, '').length === 14){
            
            const data = await getDataByCNPJ(client.current.cnpj_cpf.toString().replace(/\D/gi, ''));
            client.current.razao_social = data.nome;
            client.current.nome_fantasia = data.fantasia;
        }

    }

    const  saveNewClient = async () => {
        await updateClient(client.current);
        //props.onUpdate(props.tab);
    }

    const validadeClient = () => {
        let erros = Object.keys(client.current).filter(keys => {
            return client.current[keys] === null;
        });

        if(erros.length > 0)
            return false;

    }

    return(
        <div class="client-edit-user-data">
            <form onSubmit={(e)=> { e.preventDefault(); saveNewClient(); }}>
                
                <div className="p-float-label">
                    <Dropdown name="tipo_pessoa" value={typerPerson} options={[{label: 'Física', value: 'F'}, {label: 'Juridica', value: 'J'}]} onChange={(e) =>{ client.current[e.target.name] = e.target.value; setTypePerson(e.target.value); }} />
                    <label htmlFor="status">Tipo pessoa</label>
                </div>
                <div className="p-float-label">
                    { typerPerson ?
                        <InputMask value={cnpj_cpf} maxLength={typerPerson === 'F' ? 11 : 14} mask={typerPerson === 'F' ? '999.999.999-99' : '99.999.999/9999-99'} type='text' name='cnpj_cpf' id="cnpj_cpf" onChange={(e)=>{ client.current[e.target.name] = e.target.value; setCNPJCPF(e.target.value);  getDataPersonType() }} />
                        : '' 
                    } 
                    <label htmlFor="cnpj_cpf">{typerPerson ? (typerPerson === 'F' ? 'CPF' : 'CNPJ') : '' }</label>
                </div>
                {
                            typerPerson === 'F' ?
                                <>
                                    <div className='p-float-label person-type-f'>
                                        <InputText value={razao_social} onChange={(e)=>{ client.current[e.target.name] = e.target.value; setRazaoSocial(e.target.value);  }} required type="text" name="razao_social" id="razao_social"/>
                                        <label htmlFor="razao_social">Nome</label>
                                    </div>
                                </>
                            :
                                <>
                                    <div className="p-float-label">
                                        <InputText value={razao_social} onChange={(e)=>{client.current[e.target.name] = e.target.value; setRazaoSocial(e.target.value); }} required type="text" name="razao_social" id="razao_social"/>
                                        <label htmlFor="razao_social">Razão Social</label>
                                    </div>
                                    <div className="p-float-label">
                                        <InputText value={nome_fantasia} onChange={(e)=>{client.current[e.target.name] = e.target.value; setNomeFantasia(e.target.value); }} required type="text" name="nome_fantasia" id="nome_fantasia"/>
                                        <label htmlFor="nome_fantasia">Nome Fantasia</label>
                                    </div>
                                    <div className="p-float-label">
                                        <InputText value={insc_municipal} onChange={(e)=>{client.current[e.target.name] = e.target.value; setInscMunicipal(e.target.insc_municipal); }} type="text" name="insc_municipal" id="insc_municipal"/>
                                        <label htmlFor="insc_municipal">Inscrição Municipal</label>
                                    </div>
                                    <div className="p-float-label">
                                        <InputText value={insc_estadual} onChange={(e)=>{client.current[e.target.name] = e.target.value; setInscEstadual(e.target.value); }} type="text" name="insc_estadual" id="insc_estadual"/>
                                        <label htmlFor="insc_estadual">Inscrição Estadual</label>
                                    </div>
                                </>
                        }
                <div className="p-float-label">
                        <Dropdown name="inativo" value={ativo} options={[{label: 'Ativo', value: 1}, {label: 'Inativo', value: 2}]} onChange={(e) =>{ client.current[e.target.name] = e.target.value - 1; setAtivo(e.target.value); }} />
                    <label htmlFor="status">Status</label>
                </div>
                <div className="client-obs">
                    <Divider align="left">
                        <div className="p-d-inline-flex p-ai-center">
                            <i className="pi pi-exclamation-triangle p-mr-2"></i>
                            <b>Particularidades do cliente</b>
                        </div>
                    </Divider>
                </div>
                <div className="client-obs">
                    <InputTextarea autoResize value={observacoes} onChange={(e)=>{client.current[e.target.name] = e.target.value; setObservacoes(e.target.value); }}  name="observacoes" id="observacoes" />
                </div>
                <div className="area-buttons">
                    {/* <button onClick={()=>{
                        validadeClient();
                    }}>
                        Atualizar
                    </button> */}
                    <Button onClick={
                            ()=> {
                                props.history.push('/Client/');
                            }
                        } label="Cancelar" className="p-button-danger" icon="pi pi-times" />
                        <Button onClick={()=>{ validadeClient(); }} label="Cadastar" className="p-button-info p-mr-2" icon="pi pi-save" />
                </div> 
            </form>
        </div>
    )

}

export default ClientEditUserData;