import React, {useEffect, useState} from  'react';

import { NavLink } from 'react-router-dom';

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';

import ResumeBudget from '../../../components/resumebudget';

import {getBudgetList} from '../../../services/api/disksistemas';



import '../../../assets/style/page/user/budget/index.scss';

const PageUserBudget = (props) => {


    const [budgetList, setBudgetList] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [budgetSelected, setBudgetSelected] = useState(null);
    const [showResume, setShowResume] = useState(false);

    const getStatusName = (_status) => {

        let status;

        switch(_status.toLowerCase()){
            case 'concluído':
            case 'finalizado':
            case 'orçamento aprovado':
                status = 'success';
            break
            case 'pendente':
            case 'pendente faturamento':
            case 'aguardando aprovação':
            case 'em preparação':
                status = 'warning';
            break
            case 'cancelado':
                status = 'danger';
            break
            default:
                status = 'success';
        }

        return status;
    }


    useEffect(()=>{

        props.onSetpage('Orçamentos');

        const getData = async () => {
            const data = await getBudgetList();
            setBudgetList(data.data);
        }

        getData();

    }, [props]);

    const openResume = (id) => {

        setBudgetSelected(id);
        setShowResume(true);

    }

    return (
        <div className="page-user-budget">
            <Toolbar className="bar-add"  right={()=> <NavLink  to={`/Budget/New/`}><Button label="Adicionar orçamento" icon="pi pi-plus" iconPos="left" /></NavLink>} />
            <Toolbar className="bar-add"  right={()=> <span className="p-input-icon-left"><i className="pi pi-search" /><InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Busca..." /></span> } />
            <div className="datatable-responsive-demo">
                <DataTable globalFilter={globalFilter} value={budgetList} paginator={budgetList.length > 10} rows={10} rowsPerPageOptions={[5, 10, 25]} className="p-datatable-sm p-datatable-responsive-demo p-datatable-striped">
                    <Column style={{width:'5%'}} field="id" header="ID"></Column>
                    <Column field="cliente" header="Cliente"></Column>
                    <Column field={(budget) =>  new Date(budget.data_proposta).toLocaleDateString() + ' - ' + new Date(budget.data_proposta).toLocaleTimeString() } header="Data Proposta"></Column>
                    <Column field={(budget) =>  new Date(budget.validade_proposta).toLocaleDateString() + ' - ' + new Date(budget.validade_proposta).toLocaleTimeString() } header="Validade"></Column>
                    <Column field="criado_por" header="Responsável"></Column>
                    <Column style={{width:'16%', minWidth: '200px'}} field={(budget) => <><Tag style={{width:'100%'}} value={budget.status} severity={getStatusName(budget.status)}></Tag></> } header="Status"></Column>
                    <Column style={{width:'13%', minWidth: '200px'}} className="area-buttons" body={(budget)=> <>
                        <Button onClick={()=>{ openResume(budget.id) }} className="p-button-rounded p-button-secondary p-mr-2" icon="pi pi-info-circle" />
                        <Button onClick={()=>{ props.history.push(`./Budget/Edit/${budget.id}`) }} className="p-button-rounded p-button-warning p-mr-2" icon="pi pi-pencil" />
                        <Button className="p-button-rounded p-button-info p-mr-2" icon="pi pi-eye" />
                    </>
                    }></Column>
                </DataTable>
            </div>
            <Dialog  maximizable modal header={`Orçamento - ${budgetSelected}`} visible={showResume} style={{ width: '50vw' }}  onHide={() => setShowResume(false)}>
                <ResumeBudget budgetid={budgetSelected} />
            </Dialog>
        </div>
    )

}

export default PageUserBudget;