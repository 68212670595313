import React, {useState, useRef, useEffect} from 'react';

import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';


import {estatesList} from '../../../../services/data/index';
import {createClient, createAddress, createContact} from '../../../../services/api/disksistemas/index';

import {getDataByCNPJ, getDataByCEP} from '../../../../services/api/utils';

import '../../../../assets/style/page/user/client/new/index.scss';

const PageUserClientNew = (props) => {

    

    const client = useRef();
    const address = useRef();
    const contact = useRef();
    const [typerPerson, setTypePerson] = useState(null);
    const [showDataClient, setShowDataClient] = useState(null);
    const [addressDataRequest, setAddresDataRequest] = useState({});

    //const [tipo_pessoa, setTipoPessoa] = useState('');
    const [nome_fantasia, setNomeFantasia] = useState('');
    const [razao_social, setRazaoSocial] = useState('');
    const [cnpj_cpf, setCNPJCPF] = useState('');
    const [insc_estadual, setInscEstadual] = useState('');
    const [insc_municipal, setInscMunicipal] = useState('');
    //const [data_cadastro, setDataCasdastro] = useState('');
    const [observacoes, setObservacoes] = useState('');
    //const [ativo, setAtivo] = useState('');

    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUF] = useState('');
    const [cep, setCEP] = useState('');
    const [ponto_referencia, setPontoReferencia] = useState('');

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [ramal, setRamal] = useState('');
    const [celular, setCelular] = useState('');

    const clientData = {
        tipo_pessoa: null,
        nome_fantasia: '',
        razao_social: null,
        cnpj_cpf: '',
        insc_estadual: '',
        insc_municipal: '', 
        data_cadastro: '',
        observacoes: '', 
        ativo: 1
    };

    const addressData = {
        logradouro: null,
        numero: null,
        complemento: '',
        bairro: null,
        cidade: null,
        uf: 'null',
        cep: null,
        tipo_endereco: 'Principal',
        ponto_referencia: '',
        id_cliente: '',
        id_fornecedor: ''
    };

    const contactData = {
        nome: null,
        email: null,
        telefone: null,
        ramal: '',
        celular:'',
        tipo_contato: 'Principal',
        id_cliente: '',
        id_fornecedor: '',

    };

    client.current = client.current ? client.current : clientData;
    address.current = address.current ? address.current : addressData;
    contact.current = contact.current ? contact.current : contactData;

    useEffect(()=>{
        props.onSetpage('Cadastro Cliente');
    }, [props]);


    const getDataPersonType = async () => {
        
        if(typerPerson === 'F' && client.current.cnpj_cpf.toString().replace(/\D/gi, '').length === 11){
            
        }else if(client.current.cnpj_cpf.toString().replace(/\D/gi, '').length === 14){
            
            const data = await getDataByCNPJ(client.current.cnpj_cpf.toString().replace(/\D/gi, ''));
            client.current.razao_social = data.nome;
            client.current.nome_fantasia = data.fantasia;

            address.current.cep = data.cep.replace(/\D/gi, '');
            address.current.logradouro = data.logradouro;
            address.current.numero = data.numero;
            address.current.complemento = data.complemento;
            address.current.bairro = data.bairro;
            address.current.cidade = data.municipio;
            address.current.uf = data.uf;

            contact.current.telefone = data.telefone;
            contact.current.email = data.email;

            setRazaoSocial(data.nome);
            setNomeFantasia(data.fantasia);

            setLogradouro(data.logradouro);
            setNumero(data.numero);
            setComplemento(data.complemento);
            setBairro(data.bairro);
            setCidade(data.cidade);
            setUF(data.uf);

        }
        
    }

    const getDataAddress = async () => {
        console.log(address.current.cep.replace(/\D/gi, ''));
        const data = await getDataByCEP(address.current.cep.replace(/\D/gi, ''));
        address.current.logradouro = data.logradouro;
        address.current.bairro = data.bairro;
        address.current.cidade = data.localidade;
        address.current.uf = data.uf;

        setLogradouro(data.logradouro);
        setNumero(data.numero);
        setComplemento(data.complemento);
        setBairro(data.bairro);
        setCidade(data.localidade);
        setUF(data.uf);

        setAddresDataRequest(new Date().getTime());
    }

    const  saveNewClient = async () => {
        const clientid =  await createClient(client.current);
        address.current.id_cliente = contact.current.id_cliente = clientid.data;
        address.current.id_fornecedor = contact.current.id_fornecedor = null;
        await createAddress(address.current);
        await createContact(contact.current);
    }

    const validadeNewClient = () => {
        let errosClient = Object.keys(client.current).filter(keys => {
            return client.current[keys] === null;
        });

        let errosAddress = Object.keys(address.current).filter(keys => {
            return address.current[keys] === null;
        });

        let errosContact = Object.keys(contact.current).filter(keys => {
            return contact.current[keys] === null;
        });
        
        let erros = [];

        erros.concat(errosClient, errosAddress, errosContact);

        if(erros.length > 0)
            return false;


    }

    return (
        <div className="page-user-client-new">
            <Divider align="left">
                <div className="p-d-inline-flex p-ai-center">
                    <i className="pi pi-user p-mr-2"></i>
                    <b>Dados do cliente</b>
                </div>
            </Divider>
            <form onSubmit={(e)=> { e.preventDefault(); saveNewClient(); }}>
            <div className="area-client">
            </div>
            <div className="p-float-label">
                <Dropdown name="tipo_pessoa" value={typerPerson} options={[{label: 'Física', value: 'F'}, {label: 'Juridica', value: 'J'}]} onChange={(e) =>{ client.current[e.target.name] = e.target.value; setTypePerson(e.target.value); setShowDataClient(true); }} />
                <label htmlFor="status">Tipo pessoa</label>
            </div>
            <div className="p-float-label">
                { typerPerson ?
                    <InputMask value={cnpj_cpf} maxLength={typerPerson === 'F' ? 11 : 14} mask={typerPerson === 'F' ? '999.999.999-99' : '99.999.999/9999-99'} type='text' name='cnpj_cpf' id="cnpj_cpf" onChange={(e)=>{ client.current[e.target.name] = e.target.value; setCNPJCPF(e.target.value); getDataPersonType() }} />
                    : '' 
                } 
                <label htmlFor="cnpj_cpf">{typerPerson ? (typerPerson === 'F' ? 'CPF' : 'CNPJ') : '' }</label>
            </div>
            {
                showDataClient ?
                    <>
                        {
                            typerPerson === 'F' ?
                                <>
                                    <div className='p-float-label person-type-f'>
                                        <InputText value={razao_social}  onChange={(e)=>{ client.current[e.target.name] = e.target.value; setRazaoSocial(e.target.value);  }} required type="text" name="razao_social" id="razao_social"/>
                                        <label htmlFor="razao_social">Nome</label>
                                    </div>
                                </>
                            :
                                <>
                                    <div className="p-float-label">
                                        <InputText value={razao_social} onChange={(e)=>{client.current[e.target.name] = e.target.value; setRazaoSocial(e.target.value); }} required type="text" name="razao_social" id="razao_social"/>
                                        <label htmlFor="razao_social">Razão Social</label>
                                    </div>
                                    <div className="p-float-label">
                                        <InputText value={nome_fantasia} onChange={(e)=>{client.current[e.target.name] = e.target.value; setNomeFantasia(e.target.value); }} required type="text" name="nome_fantasia" id="nome_fantasia"/>
                                        <label htmlFor="nome_fantasia">Nome Fantasia</label>
                                    </div>
                                    <div className="p-float-label">
                                        <InputText value={insc_municipal} onChange={(e)=>{client.current[e.target.name] = e.target.value; setInscMunicipal(e.target.insc_municipal); }} type="text" name="insc_municipal" id="insc_municipal"/>
                                        <label htmlFor="insc_municipal">Inscrição Municipal</label>
                                    </div>
                                    <div className="p-float-label">
                                        <InputText value={insc_estadual} onChange={(e)=>{client.current[e.target.name] = e.target.value; setInscEstadual(e.target.value); }} type="text" name="insc_estadual" id="insc_estadual"/>
                                        <label htmlFor="insc_estadual">Inscrição Estadual</label>
                                    </div>
                                </>
                        }
                    </>
                :
                ''
            }
            {
                true ?
                    <>
                        <div className="area-address">
                        <Divider align="left">
                            <div className="p-d-inline-flex p-ai-center">
                                <i className="pi pi-map-marker p-mr-2"></i>
                                <b>Endereço</b>
                            </div>
                        </Divider>
                        </div>
                        <div className="p-float-label" update={addressDataRequest}>
                            <InputMask mask="99.999-999" value={cep} onChange={(e)=>{ address.current[e.target.name] = e.target.value; setCEP(e.target.value); if(e.target.value.replace(/\D/gi,'').length === 8) getDataAddress();}} required  name="cep" id="cep"/>
                            <label htmlFor="cep">Cep</label>
                        </div>
                        <div className="p-float-label" >
                            <InputText value={logradouro} onChange={(e)=>{ address.current[e.target.name] = e.target.value; setLogradouro(e.target.value); }} required type="text" name="logradouro" id="logradouro"/>
                            <label htmlFor="logradoudo">Logradouro</label>
                        </div>
                        <div className="p-float-label">
                            <InputText defaultValue={numero} onChange={(e)=>{ address.current[e.target.name] = e.target.value; setNumero(e.target.value); }} required type="text" name="numero" id="numero"/>
                            <label htmlFor="numero">Número</label>
                        </div>
                        <div className="p-float-label" >
                            <InputText defaultValue={complemento} onChange={(e)=>{ address.current[e.target.name] = e.target.value; setComplemento(e.target.value); }} type="text" name="complemento" id="complemento"/>
                            <label htmlFor="complemento">Complemento</label>
                        </div>
                        <div className="p-float-label">
                            <InputText defaultValue={bairro} onChange={(e)=>{ address.current[e.target.name] = e.target.value; setBairro(e.target.value); }} required type="text" name="bairro" id="bairro"/>
                            <label htmlFor="bairro">Bairro</label>
                        </div>
                        <div className="p-float-label">
                            <InputText defaultValue={cidade} onChange={(e)=>{ address.current[e.target.name] = e.target.value; setCidade(e.target.value); }} required type="text" name="cidade" id="cidade"/>
                            <label htmlFor="cidade">Cidade</label>
                        </div>
                        <div className="p-float-label">
                            <Dropdown name="uf" value={uf} options={estatesList} onChange={(e) =>{ address.current[e.target.name] = e.target.value; setUF(e.target.value); }} />
                            <label htmlFor="uf">Estado</label>
                        </div>
                        <div className="p-float-label">
                            <InputText value={ponto_referencia} onChange={(e)=>{ address.current[e.target.name] = e.target.value; setPontoReferencia(e.target.value); }} type="text" name="ponto_referencia" id="ponto_referencia"/>
                            <label htmlFor="ponto_referencia">Ponto Referência</label>
                        </div>
                        <div className="area-contact">
                        <Divider align="left">
                            <div className="p-d-inline-flex p-ai-center">
                                <i className="pi pi-phone p-mr-2"></i>
                                <b>Contato</b>
                            </div>
                        </Divider>
                        </div>
                        <div className="p-float-label">
                            <InputText value={nome} onChange={(e)=>{ contact.current[e.target.name] = e.target.value; setNome(e.target.value); }} required type="text" name="nome" id="contact_name"/>
                            <label htmlFor="contact_name">Nome</label>
                        </div>
                        <div className="p-float-label">
                            <InputText value={email} onChange={(e)=>{ contact.current[e.target.name] = e.target.value; setEmail(e.target.value); }} required type="text" name="email" id="contact_email"/>
                            <label htmlFor="contact_email">E-mail</label>
                        </div>
                        <div className="area-phone">
                            <span>
                                <span className="p-float-label">
                                    <InputMask mask="(99) 99999-9999" pattern="[0-9]{11}" value={telefone} onChange={(e)=>{ contact.current[e.target.name] = e.target.value; setTelefone(e.target.value); }} required type="text" name="telefone" id="contact_telefone"/>
                                    <label htmlFor="contact_telefone">Telefone</label>
                                </span>
                            </span>
                            <span>
                                <span className="p-float-label">
                                    <InputText value={ramal} onChange={(e)=>{ contact.current[e.target.name] = e.target.value; setRamal(e.target.value); }}  type="text" name="ramal" id="contact_ramal"/>
                                    <label htmlFor="contact_ramal">Ramal</label>
                                </span>
                            </span>
                        </div>
                        <div className="p-float-label">
                            <InputMask value={celular} mask="(99) 99999-9999" pattern="[0-9]{11}" onChange={(e)=>{ contact.current[e.target.name] = e.target.value; setCelular(e.target.value); }} type="text" name="celular" id="contact_celular"/>
                            <label htmlFor="contact_celular">Celular</label>
                        </div>
                        <div className="client-obs">
                            <Divider align="left">
                            <div className="p-d-inline-flex p-ai-center">
                                <i className="pi pi-exclamation-triangle p-mr-2"></i>
                                <b>Particularidades do cliente</b>
                            </div>
                        </Divider>
                        </div>
                        <div className="client-obs">
                            <InputTextarea autoResize value={observacoes} onChange={(e)=>{client.current[e.target.name] = e.target.value; setObservacoes(e.target.value); }}  name="observacoes" id="observacoes" />
                        </div>
                        <div className="area-buttons">
                        <Button onClick={
                            ()=> {
                                props.history.push('/User/Client/');
                            }
                        } label="Cancelar" className="p-button-danger" icon="pi pi-times" />
                        <Button onClick={()=>{ validadeNewClient(); }} label="Cadastar" className="p-button-info p-mr-2" icon="pi pi-save" />
                            {/* <NavLink className='button' to={`/Client/`}>
                            Cancelar
                            </NavLink>
                            <button onClick={()=>{
                                validadeNewClient();
                            }}>
                            Cadastrar
                            </button> */}
                        </div> 
                    </>
                : ''
            }
            </form>
        </div>
    )
}

export default PageUserClientNew;