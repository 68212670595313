import React, {useRef, useState, useEffect} from 'react';

import {estatesList} from '../../../services/data/index';
import {getDataByCEP} from '../../../services/api/utils';

import {createAddress, updateAddress} from '../../../services/api/disksistemas/index';

const FormAddress = (props) => {

    const address = useRef();
    const [addressDataRequest, setAddresDataRequest] = useState(props.update);

    const addressData = {
        id: null,
        logradouro: null,
        numero: null,
        complemento: '',
        bairro: null,
        cidade: null,
        UF: 'null',
        cep: null,
        tipo_endereco: props.addressdata ? props.addressdata.tipo_endereco : 'Principal',
        ponto_referencia: '',
        id_cliente: props.id_cliente || '',
        id_fornecedor: props.id_fornecedor || ''
    };

    address.current = address.current ? address.current : addressData;

    

    const getDataAddress = async () => {
        const data = await getDataByCEP(address.current.cep);
        address.current.logradouro = data.logradouro;
        address.current.bairro = data.bairro;
        address.current.cidade = data.localidade;
        address.current.UF = data.uf;

        setAddresDataRequest(new Date().getTime());
    }

    const validadeData = () => {
        
        let erros = [];
        erros = Object.keys(address.current).filter(keys => {
            return address.current[keys] === null;
        });

        if(erros.length > 0)
            return false;


    }

    const saveAddress = async () => {
        
        console.log(address.current);

        if(props.addressdata){
            await updateAddress(address.current);
        }else{
            if(address.current.id) delete address.current.id;
            await createAddress(address.current);
        }

        props.onUpdate();
    }

    useEffect(() => {
        
        if(props.addressdata){
            Object.keys(address.current).map(key => {
                address.current[key] = props.addressdata[key];
                return key;
            })
            
            console.log('Edit', address.current);

            setAddresDataRequest(new Date().getTime());
        }
    }, [props.addressdata])

    return (
        <div className="form-address">
            <form onSubmit={(e)=> { e.preventDefault(); saveAddress(); }}>
                <div update={addressDataRequest}>
                    <label htmlFor="cep">Cep</label>
                    <input defaultValue={address.current.cep} onInput={(e)=>{ address.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; if(e.currentTarget.value.length === 8) getDataAddress();}} required type="text" name="cep" id="cep"/>
                </div>
                <div >
                    <label htmlFor="tipo_endereco">Tipo endereço</label>
                    <input defaultValue={address.current.tipo_endereco} onInput={(e)=>{ address.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} required type="text" name="tipo_endereco" id="tipo_endereco"/>
                </div>
                <div >
                    <label htmlFor="logradoudo">Logradouro</label>
                    <input defaultValue={address.current.logradouro} onInput={(e)=>{ address.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} required type="text" name="logradouro" id="logradouro"/>
                </div>
                <div>
                    <label htmlFor="numero">Número</label>
                    <input defaultValue={address.current.numero} onInput={(e)=>{ address.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} required type="text" name="numero" id="numero"/>
                </div>
                <div >
                    <label htmlFor="complemento">Complemento</label>
                    <input defaultValue={address.current.complemento} onInput={(e)=>{ address.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} type="text" name="complemento" id="complemento"/>
                </div>
                <div>
                    <label htmlFor="bairro">Bairro</label>
                    <input defaultValue={address.current.bairro} onInput={(e)=>{ address.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} required type="text" name="bairro" id="bairro"/>
                </div>
                <div >
                    <label htmlFor="cidade">Cidade</label>
                    <input defaultValue={address.current.cidade} onInput={(e)=>{ address.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} required type="text" name="cidade" id="cidade"/>
                </div>
                <div>
                    <label htmlFor="UF">Estado</label>
                    <select defaultValue={address.current.UF} key={`estate-${address.current.UF}`} onInput={(e)=>{ address.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} required name="UF" id="UF">
                        <option defaultValue disabled value='null'>Selecione</option>
                        {
                            estatesList.map((item, id) => {
                                return item.value === address.current.UF ? <option key={`estate-${id}`} defaultValue value={item.value}>{item.label}</option> : <option key={`estate-${id}`} value={item.value}>{item.label}</option>;
                            })
                        }
                    </select>
                </div>
                <div >
                    <label htmlFor="ponto_referencia">Ponto Referência</label>
                    <input defaultValue={address.current.ponto_referencia} onInput={(e)=>{ address.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} type="text" name="ponto_referencia" id="ponto_referencia"/>
                </div>
                <div className="area-buttons">
                    <button onClick={()=>{
                        props.onClose(false);
                    }}>
                    Cancelar
                    </button>
                    <button onClick={()=>{
                        validadeData();
                    }}>
                        {props.addressdata ? 'Atualizar' : 'Cadastrar'}
                    </button>
                </div>
            </form>
        </div>
    )

}

export default FormAddress;