import React, {useState, useEffect, useRef} from 'react';

import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import {Password} from 'primereact/password';
//import { ToggleButton } from 'primereact/togglebutton';

import {getUserById, getSecurityUserById} from '../../../../services/api/disksistemas';

import {careerList, rulesUser} from '../../../../services/data';

import {updateUser, updateSecurity} from '../../../../services/api/disksistemas';

import '../../../../assets/style/page/user/users/edit/index.scss';

const PageUserUsersEdit = (props) => {

    

    const [isAdmin, setIsAdmin] = useState(false);
    const [isAlterPassword, setAlterPassword] = useState(false);
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [celular, setCelular] = useState('');
    const [inativo, setInativo] = useState('');
    const [nivelAcesso, setNivelAcesso] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');

    const userData = {
        nome: null,
        email: null, 
        telefone: null,
        celular: null,
        inativo: false,
        senha: null,
        confirmsenha: null,
        nivel_acesso: null,
        cargo:''
    };

    const [dataUser, setUser] = useState(null);
    const user = useRef();
    const rules  = useRef(null);


    user.current = user.current ? user.current : userData; 
    
    const getDataSecurity = (base, id) => {
        const data = base.filter( item => {
            return item.data === id;
        });
        
        return data[0].data; 
    }
    
    useEffect(()=> {
        
        const getData = async () => {
            
            const r =  await getSecurityUserById(props.match.params.id);
            rules.current = r.data;
            console.log(rules.current);

            
            setIsAdmin(~~rules.current.administrador === 1);
            
            const data  =  await getUserById(props.match.params.id);
            user.current = data.data;
            user.current.alterPassword = false;
            user.current.confirmsenha = false;

            console.log(data);

            setNome(data.data.nome);
            setEmail(data.data.email);
            setTelefone(data.data.telefone);
            setCelular(data.data.celular);
            setInativo(~~data.data.inativo + 1);
            setNivelAcesso(~~data.data.nivel_acesso);
            setUser(data.data);
            //props.onSetpage('Edição de Usuário');

            console.log(dataUser);
            
            //setUser(data.data);
            
        }

        getData();

    }, [props, dataUser]);


    useEffect(()=> {
        props.onSetpage('Edição de Usuário');
        
    }, [props]);

    const  saveUpdateUser = async () => {
        //delete user.current.confirmsenha;
        
        let saveuser = user.current;
        delete saveuser.confirmsenha;
        if(!isAlterPassword)
            delete saveuser.senha;

        console.log('Values Update', saveuser);

        const userid = await updateUser(saveuser);

        console.log('User id', userid);

        console.log('Rules', rules.current);

        const securiry = await updateSecurity(rules.current);

        console.log('Security id', securiry);

    }

    const validadeNewUser = () => {
        let erros = Object.keys(user.current).filter(keys => {
            return user.current[keys] === null;
        });

        if((user.current.senha !== user.current.confirmsenha) && isAlterPassword)
            erros.push('senha');
        
        console.log(erros);

        if(erros.length > 0)
            return false;
    }

    return (
        <div className="page-user-users-edit">
            <div className="data-user">
                <Divider align="left">
                    <div className="p-d-inline-flex p-ai-center">
                        <i className="pi pi-user p-mr-2"></i>
                        <b>Dados do usuário</b>
                    </div>
                </Divider>
                <form onSubmit={(e)=> { e.preventDefault(); saveUpdateUser(); }}>
                <div className="p-float-label">
                        <InputText value={nome} onChange={(e)=> {user.current[e.target.name] = e.target.value; setNome(e.target.value); }} required name="nome" id="nome"/>
                        <label htmlFor="nome">Nome</label>
                    </div>
                    <div className="p-float-label">
                        <InputText value={email} onChange={(e)=>{  user.current[e.target.name] = e.target.value; setEmail(e.target.value); }} required name="email" id="email"/>
                        <label htmlFor="email">E-mail</label>
                    </div>
                    <div className="p-float-label">
                        <InputMask value={telefone} mask="(99) 99999-9999" pattern="[0-9]{11}" onChange={(e)=>{  user.current[e.target.name] = e.target.value; setTelefone(e.target.value); }} required type="tel" name="telefone" id="telefone"/>
                        <label htmlFor="telefone">Telefone</label>
                    </div>
                    <div className="p-float-label">
                        <InputMask value={celular} mask="(99) 99999-9999" pattern="[0-9]{11}" onChange={(e)=>{  user.current[e.target.name] = e.target.value; setCelular(e.target.value); }} required type="tel" name="celular" id="celular"/>
                        <label htmlFor="celular">Celular</label>
                    </div>
                    <div className="p-float-label">
                        <Dropdown name="nivel_acesso" value={nivelAcesso} options={careerList} onChange={(e) =>{ user.current[e.target.name] = e.target.value === 1 ? e.target.value - 1 : e.target.value;  setNivelAcesso(e.target.value); }} />
                        <label htmlFor="nivel_acesso">Profissional</label>
                    </div>
                    <div className="p-float-label">
                         <Dropdown name="inativo" value={inativo} options={[{label: 'Ativo', value: 1}, {label: 'Inativo', value: 2}]} onChange={(e) =>{ user.current[e.target.name] = e.target.value - 1; setInativo(e.target.value); }} />
                        <label htmlFor="status">Status</label>
                    </div>
                    <div className="area-alter-password">
                            <div>
                                Alterar Senha?   
                            </div>
                            <div>
                                <input checked={isAlterPassword} onInput={(e)=>{ setAlterPassword(!e.currentTarget.checked); }} type="checkbox" name="alterpassword" id="alterpassword"/>
                            </div>
                        </div>
                        {
                            isAlterPassword ? 
                                <>
                                    <div className="p-float-label">
                                        <Password value={senha} toggleMask feedback={false} onChange={(e)=>{  user.current[e.target.name] = e.target.value; setSenha(e.target.value); }} required  name="senha" id="senha"/>
                                        <label htmlFor="senha">Senha</label>
                                    </div>
                                    <div className="p-float-label">
                                        <Password value={confirmarSenha} feedback={false} toggleMask onChange={(e)=>{  user.current[e.target.name] = e.target.value; setConfirmarSenha(e.target.value); }} required name="confirmsenha" id="confirmsenha"/>
                                        <label htmlFor="confirmsenha">Confirmar</label>
                                    </div>
                                </>
                            : ''
                        }
                    <div className="rules">
                        <Divider align="left">
                            <div className="p-d-inline-flex p-ai-center">
                                <i className="pi pi-lock p-mr-2"></i>
                                <b>Acessos</b>
                            </div>
                        </Divider>
                        <div className="area-admin">
                            <div>
                                Adminstrador do sistema? 
                            </div>
                            <div>
                                {isAdmin ? <input defaultChecked onInput={(e)=>{ setIsAdmin(e.currentTarget.checked); rules.current.administrador = e.currentTarget.checked ? 1 : 0; }} type="checkbox" name="administrador" id="administrador"/> : <input onInput={(e)=>{ setIsAdmin(e.currentTarget.checked); rules.current.administrador = e.currentTarget.checked ? 1 : 0; }} type="checkbox" name="administrador" id="administrador"/>}
                            </div>
                        </div>
                        <div className={`table ${isAdmin ? 'block' : ''}`}>
                            <div className="header">
                                <div>Módulo</div>
                                <div>Área</div>
                                <div>Nenhum</div>
                                <div>Leitura</div>
                                <div>Edição</div>
                            </div>
                            <div className='list-rules'>
                            <ul>
                            {
                                Object.keys(rulesUser).map((rule, rid) => {
                                    return (<>
                                        
                                        {
                                            rulesUser[rule].data.map((module, mid) => {
                                                return (
                                                    <li key={`label-${module.ref}-${mid}`}>
                                                        <div>
                                                            {rulesUser[rule].name}
                                                        </div>
                                                        <div>
                                                            {module.name}
                                                        </div>
                                                        <div className="options-rules">
                                                            {
                                                                module.data.map((options, id) => {
                                                                    return (
                                                                        <span  key={`input-$${module.ref}-${options.name}`}>
                                                                            {
                                                                                (rules.current ? ~~options.data === getDataSecurity(module.data, ~~rules.current[module.ref]) : false) ?
                                                                                    <input onInput={(e)=>{ rules.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} type="radio" defaultChecked name={module.ref} id={`${module.ref}-${options.name}`} defaultValue={options.data}/> 
                                                                                :
                                                                                    <input onInput={(e)=>{ rules.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} type="radio" name={module.ref} id={`${module.ref}-${options.name}`} defaultValue={options.data}/> 
                                                                            }
                                                                            <label htmlFor={`${module.ref}-${options.name}`}>
                                                                                {options.name}
                                                                            </label>
                                                                        </span>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </>)
                                })
                            }
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div className="area-buttons">
                        <Button onClick={
                            ()=> {
                                props.history.push('/User/Users/');
                            }
                        } label="Cancelar" className="p-button-danger" icon="pi pi-times" />
                        <Button onClick={()=>{ validadeNewUser(); }} label="Salvar" className="p-button-info p-mr-2" icon="pi pi-save" />
                             {/* <NavLink className='button' to={`/Users/`}>
                                Cancelar
                            </NavLink>
                            <button onClick={()=>{
                                validadeNewUser();
                            }}>
                                Salvar
                            </button> */}
                    </div>
                </form>
            </div>
        </div>
    )

}

export default PageUserUsersEdit;