import React from 'react';

import { ProgressBar } from 'primereact/progressbar';
 

const UIPrealoder = () => {

    return (
        <ProgressBar mode="indeterminate" style={{ margin: '5px auto' ,height: '6px' }}></ProgressBar>
    )

}

export default UIPrealoder;