import React, {useState, useEffect, createElement} from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';

import FormContact from '../../../../../components/contact/form';
import ContactView from '../../../../../components/contact/view';
import UIPrealoder from '../../../../../components/ui/preloader';

import {getContactById, deleteContact} from '../../../../../services/api/disksistemas/index';

const ClientEditContact = (props) => {

    const [listContact, setContactList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState('');


    useEffect(()=>{
        
        const getData = async () => {
            const data = await getContactById(props.match.params.id);
            console.log(data.data);
            setContactList(data.data);
        }

        getData();

    }, [props.match.params.id]);

    const closeContent = (_bl) => {
        setContentModal('');
        setShowModal(_bl);
    }

    const createNewContact = () => {
        const elem = createElement(FormContact, { onClose: closeContent, id_cliente: props.match.params.id, onUpdate: updateList, ...props});
        setContentModal(elem);
        setShowModal(true);
    }

    const viewContact = (id) => {

        const data = listContact.filter(item => item.id === id)[0];

        const elem = createElement(ContactView, { onClose: closeContent, onUpdate: updateList, contactdata: data, ...props});
        setContentModal(elem);
        setShowModal(true);
    }

    const editContact = (id) => {

        const data = listContact.filter(item => item.id === id)[0];

        const elem = createElement(FormContact, { onClose: closeContent, onUpdate: updateList, contactdata: data, ...props});
        setContentModal(elem);
        setShowModal(true);
    }

    const deleteContactExecute = async (id) => {
        
        confirmDialog({
            message: 'Tem certeza que deseja apagar o registro?',
            header: 'Atenção',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                await deleteContact({id: id});
                updateList();
            },
            reject: () => {}
        });
    }

    const updateList = async () => {
        const data = await getContactById(props.match.params.id);
        console.log(data.data);
        setShowModal(false);
        setContentModal('');
        setContactList(data.data);
    }

    return(
        <div class="client-edit-address">
            {/* <div class="area-new-address">
                <button className='button' onClick={() => { createNewContact() }}>
                    <Icon icon={userPlus} /> Adicionar Contato
                </button>
            </div>
            <div class="header">
                <div class="type">
                    Tipo
                </div>
                <div class="address">
                    Contato
                </div>
            </div>
            {
                listContact.map(_contact => {
                    return(
                        <div className="address-item" key={`address-id-${_contact.id}`}>
                            <div>{_contact.tipo_contato}</div>
                            <div>{_contact.nome} {_contact.email}, {_contact.celular} - {_contact.telefone} - {_contact.ramal}</div> 
                            <div className='area-buttons'>
                                <Icon icon={eye} onClick={()=>{ viewContact(_contact.id) }} />
                                <Icon icon={pencil} onClick={()=>{ editContact(_contact.id) }} />
                                <Icon icon={trash} onClick={() => {deleteContactExecute(_contact.id)}} />
                            </div>
                        </div>
                    )
                })
            }
            {
                createPortal(
                    <UIModal update={new Date().getTime()} onClose={closeContent} visible={showModal}>
                        {contentModal}
                    </UIModal>
                ,document.querySelector('#modal-edit-client'))
            } */}

            {!listContact ? <UIPrealoder /> : ''}
            
            <Toolbar className="bar-add"  right={()=>  <Button onClick={() => { createNewContact() }} label="Adicionar contato" icon="pi pi-plus" iconPos="left" /> } />

            <DataTable value={listContact} paginator={listContact?.length > 10} rows={10} rowsPerPageOptions={[5, 10, 25]} className="p-datatable-sm p-datatable-responsive-demo p-datatable-striped">
                <Column style={{width: '10rem'}} field="tipo_contato" header="Tipo"></Column>
                <Column body={
                    (_contact)=> <div>{_contact.nome} {_contact.email}, {_contact.celular} - {_contact.telefone} - {_contact.ramal}</div> 
                } header="Contato"></Column>
                <Column style={{width:'12%', minWidth: '200px'}} className="area-buttons" body={(row)=> <>
                        <Button onClick={()=>{ viewContact(row.id) }} className="p-button-rounded p-button-info p-mr-2" icon="pi pi-eye" />
                        <Button onClick={()=>{ editContact(row.id) }} className="p-button-rounded p-button-warning p-mr-2" icon="pi pi-pencil" />
                        <Button  onClick={() => {deleteContactExecute(row.id)}}  className="p-button-rounded p-button-danger p-mr-2" icon="pi pi-trash" />
                    </>
                    }></Column>
            </DataTable>
            <Dialog visible={showModal} style={{ width: '50vw' }} onHide={() => setShowModal(false)}>
                {contentModal}
            </Dialog>
        </div>
    )

}

export default ClientEditContact;