import React, {useEffect, useState, createRef} from 'react';

import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import ResumeBudget from '../../../components/resumebudget';

import Chart from 'chart.js';

import {getUserList, getClientList, getBudgetList} from '../../../services/api/disksistemas';

import '../../../assets/style//page/user/dashboard/index.scss';

const PageUserDashboard = (props) => {

    const [userList, setUserList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [budgetList, setBudgetList] = useState([]);
    const [budgetSelected, setBudgetSelected] = useState(null);
    const [showResume, setShowResume] = useState(false);

    const chart = createRef();
    const chartData = createRef();

    const getStatusName = (_status) => {

        let status;

        switch(_status.toLowerCase()){
            case 'concluído':
            case 'finalizado':
            case 'orçamento aprovado':
                status = 'success';
            break
            case 'pendente':
            case 'pendente faturamento':
            case 'aguardando aprovação':
            case 'em preparação':
                status = 'warning';
            break
            case 'cancelado':
                status = 'danger';
            break
            default:
                status = 'success';
        }

        return status;
    }

    useEffect(()=> {

        props.onSetpage('Dashboard');

        const getData = async () => {
            const users = await getUserList();
            setUserList(users.data);

            const clients = await getClientList();
            setClientList(clients.data);    

            const budget = await getBudgetList();
            setBudgetList(budget.data.filter(item => !/(concluído|aprovado)/gi.test(item.status)));
        };
        
        getData();
        
    },[props]);
    
    
    useEffect(()=>{

        
        const getData = async () => {
            
            const budget = await getBudgetList();
            let chartDataTemp = {};

            budget.data.map((item, id )=> {
                
                if(chartDataTemp[item.status]){
                    chartDataTemp[item.status] += 1;
                }else{
                    chartDataTemp[item.status] = 1;
                }

                return item;
            });

             let chartValue = {
                type: 'pie',
                data:{
                    labels: [],
                    datasets: [
                        {
                            data: [],
                            backgroundColor: [
                                '#86c8e2',
                                '#F9D953',
                                '#9ED880',
                                '#5A449A'
                            ]
                        }
                    ]
                },
                options: {
                    animation: false,
                    legend:{
                        display: true,
                            position: 'bottom',
                            labels: {
                                color: 'rgb(255, 99, 132)'
                            }
                    }
                }
            }

             Object.keys(chartDataTemp).map(item => {
                  chartValue.data.labels.push(item);
                  chartValue.data.datasets[0].data.push(chartDataTemp[item]);
                   return item;
               })

            chartData.current = chartValue;
            
            
            if(chart.current)
                 new Chart(chart.current.getContext('2d'), chartData.current);

        }

        getData();

    },[chart, chartData]);

    const openResume = (id) => {

        setBudgetSelected(id);
        setShowResume(true);

    }

    return(
        <div className='PageUserDashboard'>
            <div className="area-box">
                <div className="box client">
                    <div className="title"> 
                        Clientes cadastrados
                    </div>
                    <div className="content">
                        {clientList.length}
                    </div>
                </div>
                <div className="box client-active">
                    <div className="title">
                        Clientes Ativos
                    </div>
                    <div className="content">
                        {clientList.filter(item => ~~item.ativo === 1).length}
                    </div>
                </div>
                <div className="box user">
                    <div className="title"> 
                        Usuários cadastrados
                    </div>
                    <div className="content">
                        {userList.length}
                    </div>
                </div>
                <div className="box user-active">
                    <div className="title">
                        Usuários Ativos
                    </div>
                    <div className="content">
                        {userList.filter(item => ~~item.inativo === 0).length}
                    </div>
                </div>
            </div>
            <Divider align="left">
                <div className="p-d-inline-flex p-ai-center">
                    <i className="pi pi-dollar p-mr-2"></i>
                    <b>Orçamentos</b>
                </div>
            </Divider>
            <div className="area-charts">
                <div>
                    <canvas ref={chart}></canvas>
                </div>
                <div>
                    <div className="datatable-responsive-demo">
                        <DataTable value={budgetList} paginator={budgetList.length > 5} rows={5}  className="p-datatable-sm p-datatable-responsive-demo p-datatable-striped">
                            <Column style={{width:'5%'}} field="id" header="ID"></Column>
                            <Column style={{minWidth: '200px'}} field="cliente" header="Cliente"></Column>
                            <Column field={(budget) =>  new Date(budget.validade_proposta).toLocaleDateString() + ' - ' + new Date(budget.validade_proposta).toLocaleTimeString() } header="Validade"></Column>
                            <Column style={{width:'25%', minWidth: '250px'}} field={(budget) => <><Tag style={{width:'100%'}} value={budget.status} severity={getStatusName(budget.status)}></Tag></> } header="Status"></Column>
                            <Column  style={{width:'16%', minWidth: '150px'}} className="area-buttons" body={(budget)=> <>
                                    <Button onClick={()=>{ openResume(budget.id) }} className="p-button-rounded p-button-secondary p-mr-2" icon="pi pi-info-circle" />
                                    <Button className="p-button-rounded p-button-info p-mr-2" icon="pi pi-eye" />
                                </>}
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
            <Dialog  maximizable modal header={`Orçamento - ${budgetSelected}`} visible={showResume} style={{ width: '50vw' }}  onHide={() => setShowResume(false)}>
                <ResumeBudget budgetid={budgetSelected} />
            </Dialog>
            <Divider align="left">
                <div className="p-d-inline-flex p-ai-center">
                    <i className="pi pi-ticket p-mr-2"></i>
                    <b>Ordem de serviços</b>
                </div>
            </Divider>
            <div className="area-char">
                <div></div>
                <div></div>
            </div>
            <Divider align="left">
                <div className="p-d-inline-flex p-ai-center">
                    <i className="pi pi-book p-mr-2"></i>
                    <b>Contratos</b>
                </div>
            </Divider>
            <div className="area-char">
                <div></div>
                <div></div>
            </div>
        </div>
    )
};

export default PageUserDashboard;