import React, {useRef, useState, useEffect} from 'react';

import {createContact, updateContact} from '../../../services/api/disksistemas/index';

const FormContact = (props) => {

    const contact = useRef();
    const [contactDataRequest, setAddresDataRequest] = useState(props.update);

    const contactData = {
        id: null,
        nome: null,
        email: null,
        telefone: null,
        ramal: '',
        celular:'',
        tipo_contato: props.contactdata ? props.contactdata.tipo_contato : '',
        id_cliente: props.id_cliente || null,
        id_fornecedor: props.id_fornecedor || null

    };

    contact.current = contact.current ? contact.current : contactData;

    const validadeData = () => {
        
        let erros = [];
        erros = Object.keys(contact.current).filter(keys => {
            return contact.current[keys] === null;
        });

        if(erros.length > 0)
            return false;


    }

    const saveContact = async () => {
        
        console.log(contact.current);

        if(props.contactdata){
            await updateContact(contact.current);
        }else{
            if(contact.current.id) delete contact.current.id;
            await createContact(contact.current);
        }

        props.onUpdate();
    }

    useEffect(() => {
        
        if(props.contactdata){
            Object.keys(contact.current).map(key => {
                contact.current[key] = props.contactdata[key];
                return key;
            })
            
            console.log('Edit', contact.current);

            setAddresDataRequest(new Date().getTime());
        }
    }, [props.contactdata])

    return (
        <div className="form-contact">
            <form update={contactDataRequest} onSubmit={(e)=> { e.preventDefault(); saveContact(); }}>
                <div >
                    <label htmlFor="tipo_contato">Tipo contato</label>
                    <input defaultValue={contact.current.tipo_contato} onInput={(e)=>{ contact.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} required type="text" name="tipo_contato" id="tipo_contato"/>
                </div>
                <div>
                    <label htmlFor="contact_name">Nome</label>
                    <input defaultValue={contact.current.nome} onInput={(e)=>{ contact.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} required type="text" name="nome" id="contact_name"/>
                </div>
                <div >
                    <label htmlFor="contact_email">E-mail</label>
                    <input defaultValue={contact.current.email} onInput={(e)=>{ contact.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} required type="text" name="email" id="contact_email"/>
                </div>
                <div className="area-phone">
                    <label htmlFor="contact_telefone">Telefone</label>
                    <input defaultValue={contact.current.telefone} onInput={(e)=>{ contact.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} required type="text" name="telefone" id="contact_telefone"/>
                    <label htmlFor="contact_ramal">Ramal</label>
                    <input defaultValue={contact.current.ramal} onInput={(e)=>{ contact.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }}  type="text" name="ramal" id="contact_ramal"/>
                </div>
                <div >
                    <label htmlFor="contact_celular">Celular</label>
                    <input defaultValue={contact.current.celular} onInput={(e)=>{ contact.current[e.currentTarget.getAttribute('name')] = e.currentTarget.value; }} type="text" name="celular" id="contact_celular"/>
                </div>
                <div className="area-buttons">
                    <button onClick={()=>{
                        props.onClose(false);
                    }}>
                    Cancelar
                    </button>
                    <button onClick={()=>{
                        validadeData();
                    }}>
                        {props.contactdata ? 'Atualizar' : 'Cadastrar'}
                    </button>
                </div>
            </form>
        </div>
    )

}

export default FormContact;