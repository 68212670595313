import React, {useState, useRef, useEffect, createElement, useCallback} from 'react';

import { TabMenu } from 'primereact/tabmenu';

import ClientEditUserData from './userdata';
import ClientEditAddress from './address';
import ClientEditContact from './contact';

import {getClientById} from '../../../../services/api/disksistemas/index';


import '../../../../assets/style/page/user/client/edit/index.scss';

const PageUserClientEdit = (props) => {

    const items = useRef([
        {label: 'Dados', icon: 'pi pi-fw pi-user', tabname: 'clientdata', id: 0},
        {label: 'Endereços', icon: 'pi pi-fw pi-map-marker', tabname: 'address', id: 1},
        {label: 'Contatos', icon: 'pi pi-fw pi-phone', tabname: 'contact', id: 2}
    ]);

    const client = useRef();
    const address = useRef();
    const contact = useRef();
    const [navTab, setNavTab] = useState('clientdata');
    const [pageTab, setPageTab] = useState(null);
    const [pageTabId, setPageTabid] = useState('0');
    const [activeItem, setActiveItem] = useState(items.current[0]);

    const clientData = {
        tipo_pessoa: null,
        nome_fantasia: '',
        razao_social: null,
        cnpj_cpf: '',
        insc_estadual: '',
        insc_municipal: '', 
        data_cadastro: '',
        observacoes: '', 
        ativo: 1
    };

    const addressData = {
        logradouro: null,
        numero: null,
        complemento: '',
        bairro: null,
        cidade: null,
        uf: 'null',
        cep: null,
        tipo_endereco: 'Principal',
        ponto_referencia: '',
        id_cliente: '',
        id_fornecedor: ''
    };

    const contactData = {
        nome: null,
        email: null,
        telefone: null,
        ramal: '',
        celular:'',
        tipo_contato: '',
        id_cliente: '',
        id_fornecedor: '',

    };

    client.current = client.current ? client.current : clientData;
    address.current = address.current ? address.current : addressData;
    contact.current = contact.current ? contact.current : contactData;


    const navigationTab = useCallback((_tab = null) => {
        setNavTab(_tab);
        let page;
        switch(_tab){
            case 'address':
                page = createElement(ClientEditAddress, {...props});
            break;
            case 'contact':
                page = createElement(ClientEditContact, {...props});
            break;
            default:
                page = createElement(ClientEditUserData, {...props});        
        }
        
        //setPageTabid(new Date().getTime());
        setPageTab(page);
    }, [props])

    //const start = useCallback(() => navigationTab(), [null]);

    useEffect(()=>{

        navigationTab(navTab);

    },[navigationTab, navTab]);

    useEffect(()=>{
        props.onSetpage('Edição do Cliente');

    }, [props]);

    useEffect(()=>{
        
        const getData = async () => {
            const data = await getClientById(props.match.params.id);
            client.current =  data.data;
        }

        getData();

    }, [props.match.params.id]);

        

    return (
        <div className="page-user-client-edit">
            
            <TabMenu model={items.current} activeItem={activeItem} onTabChange={(e) =>{ setPageTabid(new Date().getTime()); console.log(e); setActiveItem(e.value); navigationTab(e.value.tabname);}}  />

            <div key={`${navTab}-${pageTabId}`}>{pageTab}</div>
            <div id="modal-edit-client"></div>  
        </div>
    )
}

export default PageUserClientEdit;